import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams, useLocation } from 'react-router-dom';
import { getIngresoById } from '../../controllers/ingresos';
import { getClienteByID } from '../../controllers/clientes';
import { getUbicacionesById } from '../../controllers/ubicaciones';
import { getUnidadByID, getRemolquesByNombreAndUid } from '../../controllers/unidades';
import { getUserByUid } from '../../controllers/usuarios';
import { getOperadorById } from '../../controllers/operadores';
import moment from 'moment';
import qrcode from 'qrcode.react';
import queryString from 'query-string';
import xml2js from 'xml2js';
import { NumeroALetras } from './numaletras';
import { parseBooleans } from 'xml2js/lib/processors';
import zIndex from '@material-ui/core/styles/zIndex';
//var QRCode = require('qrcode.react');
// Create styles

const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginTop: 15,
        paddingBottom: '30px',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: '0',
        width: '20%',
        // border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '40%',
        margin: 2,
        padding: 10,
        flexGrow: 2
    },
    sectionCPinfo: {
        margin: 10,
        width: '30%',
        padding: 2,
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionComentariosBottom: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionFolioTopSmall: {
        // margin: 10,
        width: '9.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopCompenzaSmall: {
        // margin: 10,
        width: '13.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopBigDetail: {
        // margin: 10,
        width: '36.22%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTop: {
        // margin: 10,
        width: '11.11%',
        height: "auto",
        //borderLeft: '1px solid black',
        // borderTop: '1px solid black',

    },
    sectionFolioTopMedium: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionFolioTopMediumNoBorder: {
        // margin: 10,
        width: '18.5vw',
        height: "35px",
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
    },
    sectionSellosBottomFirst: {
        // margin: 10,
        marginTop: '0px',
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottom: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionSellosBottomLast: {
        // margin: 10,
        width: 'auto',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePaga: {
        // margin: 10,
        width: '95vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        //   flexGrow: 1
    },

    sectionCol: {
        margin: 0,
        width: '10vw',
        height: "140px",
        border: '1px solid black',

        // flexGrow: 1
    },
    sectionColMiddle: {
        margin: 0,
        width: '13vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionColMiddleX: {
        margin: 0,
        width: '33vw',
        height: "140px",
        borderRight: '1px solid black',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        // flexGrow: 1
    },
    sectionOrigenDestino: {
        marginRight: 5,
        width: '47vw',
        height: "auto",
        border: '1px solid black',
        // flexGrow: 1
    },
    sectionLabelInfo: {
        width: '16%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionLabelInfosmall: {
        width: '29%',
        height: "auto",
        textAlign: 'right',
        backgroundColor: "#d9d9d9",
        color: "black"
    },
    sectionInfoSmall: {
        width: '71%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoNoventa: {
        width: '85%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionInfoCuarenta: {
        width: '35%',
        marginLeft: '5px',
        height: "auto",
        textAlign: 'left',
        color: "black"
    },
    sectionClientePagaHeader: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        backgroundColor: "#203764",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionClientePagaHeader2: {
        width: '100%',
        height: "auto",
        borderBottom: '1px solid black',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: "#203764",
        color: "#FFF"
        //   flexGrow: 1
    },
    sectionbajoconceptoizquierda: {
        margin: "0px",
        width: '69vw',
        height: "55px",
        //  border: '1px solid black',
    },
    sectionbajoconceptoderecha: {
        margin: 0,
        width: '26vw',
        height: "50px",
        borderRight: '1px solid black',
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
    },
    docutitulo: {
        margin: 5,
        fontSize: 10,
        fontWeight: 900,
        width: '10vw',
        height: "auto",
    },
    docutexto: {
        margin: 5,
        fontSize: 9,
        width: '80vw',
        height: "auto",
    }
});
const CartaPorte = props => {
    const CATALOGO_REGIMENESFISCALES = [
        {
            "codigo": "",
            "descripcion": "",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "622",
            "descripcion": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "606",
            "descripcion": "Arrendamiento",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "609",
            "descripcion": "Consolidación",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "624",
            "descripcion": "Coordinados",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "629",
            "descripcion": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "608",
            "descripcion": "Demás ingresos",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "630",
            "descripcion": "Enajenación de acciones en bolsa de valores",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "601",
            "descripcion": "General de Ley Personas Morales",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "628",
            "descripcion": "Hidrocarburos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "621",
            "descripcion": "Incorporación Fiscal",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "611",
            "descripcion": "Ingresos por Dividendos (socios y accionistas)",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "614",
            "descripcion": "Ingresos por intereses",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "623",
            "descripcion": "Opcional para Grupos de Sociedades",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "612",
            "descripcion": "Personas Físicas con Actividades Empresariales y Profesionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "603",
            "descripcion": "Personas Morales con Fines no Lucrativos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "607",
            "descripcion": "Régimen de Enajenación o Adquisición de Bienes",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "625",
            "descripcion": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "615",
            "descripcion": "Régimen de los ingresos por obtención de premios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "610",
            "descripcion": "Residentes en el Extranjero sin Establecimiento Permanente en México",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "616",
            "descripcion": "Sin obligaciones fiscales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "620",
            "descripcion": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "605",
            "descripcion": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "626",
            "descripcion": "Régimen Simplificado de Confianza",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        }
    ];
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Sin efectos fiscales.",
            "Value": "S01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos",
            "Value": "CP01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ];
    const CAT_TIPORELACION = [
        { value: '', descripcion: '' },
        { value: '01', descripcion: 'Notas de Crédito de Documentos Relacionados' },
        { value: '02', descripcion: 'Notas de Débito de los Documentos Relacionados' },
        { value: '03', descripcion: 'Devolución de Mercancías sobre Facturas o Traslados Previos' },
        { value: '04', descripcion: 'Sustitución de los CFDI Previos' },
        { value: '05', descripcion: 'Traslados de Mercancías Facturados Previamente' },
        { value: '06', descripcion: 'Factura Generada por los Traslados Previos' },
        { value: '07', descripcion: 'CFDI por Aplicación de Anticipo' },
        { value: '08', descripcion: 'Facturas Generadas por Pagos en Parcialidades' },
        { value: '09', descripcion: 'Factura Generada por Pagos Diferidos' }
    ];
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]
    const data = props.objdata;

    /// let fechaEntrega = moment(dateIn).format('DD/MM/YYYY HH:mm');
    //let fechaOrigen = moment(dateOut).format('DD/MM/YYYY HH:mm');

    let datacliente = data.clienteinfo;
    let dataviaje = data.datos_viaje;
    let datacp = data.carta_porte;
    let origeninfo = data.origeninfo;
    let destinoinfo = data.destinoinfo;
    let usuarioInfo = data.usuarioInfo;
    let unidadinfo = data.unidadinfo;
    let operadorinfo = data.operadorinfo;
    let paradasinfo = data.paradas;


    // let comentarios = data.detalles.comentarios;



    if (!datacliente) {
        datacliente = {};
    }
    if (!origeninfo) {
        origeninfo = {};
    }
    if (!destinoinfo) {
        destinoinfo = {};
    }
    if (!usuarioInfo) {
        //usuarioInfo = { razon: "NORAZON" };
    }

    let datatimbre = {
        TaxStamp: {}
    };
    if (data.facturamaCP) {
        datatimbre = data.facturamaCP;
    }
    let cpFactOBJ = null;
    if (data.complementos && data.complementos[0]) {
        cpFactOBJ = data.complementos[0];

        let xmlData = data.complementos[0].timbreXML;



    }
    const [credenciales, setCredenciales] = useState(usuarioInfo.credenciales || null);
    console.log("data usuarioinfo", usuarioInfo);
    console.log("data credenciales", usuarioInfo.credenciales);

    const [logotipo, setLogotipo] = useState(usuarioInfo.credenciales.logotipo ? usuarioInfo.credenciales.logotipo : usuarioInfo.logotipo || null);
    const [referencia, setReferencia] = useState(data.referencia || "");
    const [ncarta, setNcarta] = useState(`${data.datatimbrado.Serie}-${data.datatimbrado.Folio}`);

    const [folioFiscal, setFolioFiscal] = useState(data.datatimbrado ? data.datatimbrado.UUID : "-");
    const [serieSat, setSerieSat] = useState(data.datatimbrado ? data.datatimbrado.NoCertificadoSAT : "-");
    const [fechaTimbrado, setFechaTimbrado] = useState(data.datatimbrado ? data.datatimbrado.FechaTimbrado : "-");
    const [fechaCer, setFechaCer] = useState(data.datatimbrado ? data.datatimbrado.Fecha : "-");

    let dateIn = data.fecha.toDate();
    let fechaPago = moment(dateIn).format('DD/MM/YYYY');
    const [formaPago, setFormaPago] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['$']['FormaDePagoP']);
    const [monedaP, setMonedaP] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['$']['MonedaP']);
    const [TipoCambioP, setTipoCambioP] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['$']['TipoCambioP'] || "1.00");
    const [monto, setMonto] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['$']['Monto']);

    const [NumOperacion, setNumOperacion] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['$']['NumOperacion'] || "");

    const [documentosRelacionados, setDocumentosRelacionados] = useState(data.datatimbrado.Complemento[0]['pago20:Pagos'][0]['pago20:Pago'][0]['pago20:DoctoRelacionado']);
    const [relacionados, setRelacionados] = useState(data.datatimbrado.relacionados || null);

    const [usoCfdi, setUsoCfdi] = useState(data.datatimbrado ? `${data.datatimbrado.UsoCFDI} - ${CAT_USOCFDI.find(e => e.Value === data.datatimbrado.UsoCFDI).Name}` : "-");
    const [moneda, setMoneda] = useState(data.datatimbrado ? data.datatimbrado.Moneda : "-");
    const [tipoCambio, setTipoCambio] = useState(data.datatimbrado ? data.datatimbrado.TipoCambio : "-");

    const [subtotal, setsubtotal] = useState(data.datatimbrado ? data.datatimbrado.SubTotal : ".00");
    const [total, settotal] = useState(data.datatimbrado ? data.datatimbrado.Total : ".00");

    const [serieEmisor, setSerieEmisor] = useState(data.datatimbrado ? data.datatimbrado.NoCertificado : "-");

    const [sellocfdi, setSelloCfdi] = useState(cpFactOBJ ? cpFactOBJ.selloCFD : "-");
    const [sellosat, setSelloSat] = useState(cpFactOBJ ? cpFactOBJ.selloSAT : "-");
    const [cadenaOriginal, setCadenaOriginal] = useState(cpFactOBJ ? cpFactOBJ.cadenaOriginal : "-");

    const [clientenombre, setClienteNombre] = useState(datacliente.nombre || "-");
    const [clientenumero, setClienteNumero] = useState(datacliente.numero || "");
    const [clientecalle, setClientecalle] = useState(datacliente.calle || "");
    let auxcolonia = datacliente.colonia;
    if (auxcolonia === "" && datacliente.coloniaSat && datacliente.colonias) {
        let colfinded = datacliente.colonias.find(col => col.claveColonia === datacliente.coloniaSat);
        if (colfinded) {
            auxcolonia = colfinded.colonia || "";
        }
    }
    const [clientecolonia, setClientecolonia] = useState(auxcolonia || "");
    const [clientelocalidad, setClienteLocalidad] = useState(datacliente.localidad || "");
    const [clientecp, setClientecp] = useState(datacliente.cp || "");
    const [clienterfc, setClienterfc] = useState(datacliente.rfc || "XEXX010101000");
    const [clienteRegimen, setClienteRegimen] = useState(datacliente.regimen ? CATALOGO_REGIMENESFISCALES.find(rf => rf.codigo === datacliente.regimen) ? `${datacliente.regimen} - ${CATALOGO_REGIMENESFISCALES.find(rf => rf.codigo === datacliente.regimen).descripcion}` : "" : "601 - Ley General de Personas Morales.");
    const [clientestado, setClienteestado] = useState(datacliente.estado || "");

    const [origenInternacional, setOrigenInternaciona] = useState(origeninfo.internacional);
    const [origenlocation, setOrigenlocation] = useState(origeninfo.nombre || "");
    const [origenciudad, setOrigenCiudad] = useState(origeninfo.localidad || "");
    const [origenestado, setOrigenEstado] = useState(origeninfo.estado || "");
    const [origenumero, setOrigennumero] = useState(origeninfo.numero || "");
    const [origencalle, setOrigencalle] = useState(origeninfo.calle || "");
    const [origencolonia, setOrigencolonia] = useState(origeninfo.colonia || "");
    const [origencp, setOrigencp] = useState(origeninfo.cp || "");
    const [origenrfc, setOrigenrfc] = useState(origeninfo.rfc || "");
    const [origennombre, setOrigennombre] = useState(origeninfo.nombre || "");

    const [destinoInternacional, setDestinoInternaciona] = useState(destinoinfo.internacional);
    const [destinolocation, setDestinolocation] = useState(destinoinfo.nombre || "");
    const [destinociudad, setDestinoCiudad] = useState(destinoinfo.localidad || "");
    const [destinoestado, setDestinoEstado] = useState(destinoinfo.estado || "");
    const [destinonumero, setDestinonumero] = useState(destinoinfo.numero || "");
    const [destinocalle, setDestinocalle] = useState(destinoinfo.calle || "");
    const [destinocolonia, setDestinocolonia] = useState(destinoinfo.colonia || "");
    const [destinocp, setDestinocp] = useState(destinoinfo.cp || "");
    const [destinorfc, setDestinoRfc] = useState(destinoinfo.rfc || "");
    const [destinnombre, setDestinonombre] = useState(destinoinfo.nombre || "");

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const [datosTimbre, setDatosTimbre] = useState(data.complementos || null);

    let pedis = ""

    pedis = pedis.trim();

    const [paradas, setParadas] = useState(data.paradas || []);
    const [conceptos, setConceptos] = useState(data.datatimbrado.Conceptos || []);
    const [impuestos, setImpuestos] = useState(data.datatimbrado.Impuestos || []);

    const [razon, setRazon] = useState(usuarioInfo.credenciales.Razon || "");
    const [rfcuser, setRfcUser] = useState(usuarioInfo.credenciales.Rfc || "");
    let direcccionfixed = `${usuarioInfo.credenciales.direccion.calle} ${usuarioInfo.credenciales.direccion.numero} Colonia ${usuarioInfo.credenciales.direccion.colonia} CP ${usuarioInfo.credenciales.direccion.cp} ${usuarioInfo.credenciales.direccion.localidad} ${usuarioInfo.credenciales.direccion.estado}`;
    const [dirCalle, setDirCalle] = useState(usuarioInfo.credenciales.direccion.calle || " ");
    const [dirNumero, setDirNumero] = useState(usuarioInfo.credenciales.direccion.numero || " ");
    const [dirColonia, setDirColonia] = useState(usuarioInfo.credenciales.direccion.colonia || " ");
    const [dirCp, setDirCp] = useState(usuarioInfo.credenciales.direccion.cp || " ");
    const [dirLocalidad, setDirLocalidad] = useState(usuarioInfo.credenciales.direccion.localidad || " ");
    const [dirEstado, setDirEstado] = useState(usuarioInfo.credenciales.direccion.estado || " ");

    //let cadenaqr="https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?id=abc\&re="+rfcuser+"\&rr="+clienterfc+"\&tt=0.000000\&fe=SeQDTA==";
    const [dataqr, setDataQR] = useState(`${data.id}` || "abc");

    useEffect(() => {
        // console.log("props", props);
    }, []);

    const formatoMoneda = (valor) => {
        let fixtostring = "" + valor;
        let fixtofloat = parseFloat(fixtostring);
        let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return toMoney;

    }

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>
                    <Image style={{ width: '100%', height: "auto", marginTop: '10px' }} src={`data:image/png;base64, ${logotipo || ""}`}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    <Text style={{ fontSize: '14px', fontWeight: 'bold' }}>{razon}</Text>
                    <Text style={{ fontSize: '9px', marginTop: '2px' }}>{rfcuser}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCalle} ${dirNumero}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirColonia}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirLocalidad}, ${dirEstado}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "2px" }}>{`${dirCp}`}</Text>
                    <Text style={{ fontSize: '9px', marginTop: "8px" }}>{referencia !== "" ? "Referencia:" + referencia : ""}</Text>

                </View>
                <View style={styles.sectionCPinfo}>


                    <Text style={{ fontSize: '9px', textAlign: 'center' }}>{`Complemento de Pago:`} <Text style={{ color: "#F00" }}>{ncarta}</Text></Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>Folio Fiscal</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{folioFiscal}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado Digital</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{serieEmisor}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>No. Certificado SAT</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{serieSat}</Text>
                    <Text style={{ fontSize: '11px', fontWeight: 'extrabold', textAlign: 'center' }}>Fecha Timbrado</Text>
                    <Text style={{ fontSize: '8px', textAlign: 'center' }}>{fechaTimbrado}</Text>
                </View>
            </View>
            <Text style={{ fontSize: '10px', fontWeight: 'bold', marginLeft: 1 }}> </Text>
            <View style={styles.sectionClientePaga}>

                <View style={styles.sectionClientePagaHeader}>
                    <Text style={{ fontSize: '11px', fontWeight: 'bold', marginLeft: 1 }}>Datos del Cliente / Bill To Customer</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 0 }}>

                    <View style={styles.sectionLabelInfo}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>RFC:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Nombre:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Calle:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Colonia:</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Localidad:</Text>
                    </View>
                    <View style={styles.sectionInfoCuarenta}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clienterfc}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientenombre}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecalle} ${clientenumero}`}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clientecolonia || "No Aplica"}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{`${clientecp},${clientelocalidad}`}</Text>
                    </View>
                    <View style={styles.sectionLabelInfo}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Lugar de Expedición</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>Régimen Fiscal</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>&nbsp;</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>&nbsp; </Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}> &nbsp;</Text>
                    </View>
                    <View style={styles.sectionInfoCuarenta}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{dirCp}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{clienteRegimen}</Text>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 0 }}>{usoCfdi}</Text>

                    </View>

                </View>
                {/*<Text style={{ fontSize: '10px', fontWeight: 'bold', marginLeft: 5 }}>{clientenombre}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientenumero} ${clientecalle} ${clientecolonia}  .  ${clientelocalidad} ${clientecp}`}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{clienterfc}</Text>
                <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 5 }}>{`${clientelocalidad}, ${clientestado}`}</Text>*/}
            </View>

            {
                relacionados && relacionados[0] && <View style={{ flexDirection: 'row', marginTop: 1, width: "95vw" }} wrap={false} >
                    <View style={styles.sectionComentariosBottom}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>CFDIS RELACIONADOS </Text>
                        </View>
                        <Text style={{ fontSize: '9px', margin: 2 }}>{`Tipo de relación: ${relacionados[0].tiporelacion} - ${CAT_TIPORELACION.find(rel => rel.value === relacionados[0].tiporelacion).descripcion}`}</Text>
                        {
                            relacionados.map(rel => {
                                return <Text style={{ fontSize: '9px', margin: 2 }}>{`UUID: ${rel.uuid}`}</Text>
                            })
                        }

                    </View>
                </View>
            }
            <View style={{ flexDirection: 'row', marginTop: "10px", width: "95vw" }}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Fecha de Pago</Text>
                    </View>

                </View>
                <View style={{ width: '30%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Forma de Pago</Text>
                    </View>

                </View>
                <View style={{ width: '10%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Moneda</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Tipo de Cambio</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Monto</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>No. Operación</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={styles.sectionClientePagaHeader}>
                        <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Banco Extr.</Text>
                    </View>
                </View>
            </View>


            <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{fechaPago || ""}</Text>
                    </View>
                </View>
                <View style={{ width: '30%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{`${formaPago} - ${CAT_FORMASPAGO.find(f => f.Value == formaPago).Name}`}</Text>
                    </View>
                </View>
                <View style={{ width: '10%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{monedaP}</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{TipoCambioP}</Text>
                    </View>
                </View>
                <View style={{ width: '20%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{formatoMoneda(monto)}</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{NumOperacion}</Text>
                    </View>
                </View>
                <View style={{ width: '12%', height: "auto" }}>
                    <View style={{ weight: "auto" }}>
                        <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{""}</Text>
                    </View>
                </View>
            </View>


            {
                data.incluirCuentas && data.incluirCuentas === "Si" &&
                <View style={{ flexDirection: 'row', marginTop: "10px", width: "95vw" }}>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC Cta.Ordenante</Text>
                        </View>
                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cta.Ordenante</Text>
                        </View>

                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>RFC Cta.Beneficiario</Text>
                        </View>
                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={styles.sectionClientePagaHeader}>
                            <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Cta.Beneficiario</Text>
                        </View>

                    </View>

                </View>
            }

            {
                data.incluirCuentas && data.incluirCuentas === "Si" &&
                <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={{ weight: "auto" }}>
                            <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{data.rfcCtaOrdenante || ""}</Text>
                        </View>
                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={{ weight: "auto" }}>
                            <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{data.cuentaOrdenante || ""}</Text>
                        </View>
                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={{ weight: "auto" }}>
                            <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{data.rfcCtaBeneficiario || ""}</Text>
                        </View>
                    </View>
                    <View style={{ width: '16%', height: "auto" }}>
                        <View style={{ weight: "auto" }}>
                            <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{data.cuentaBeneficiario || ""}</Text>
                        </View>
                    </View>
                </View>
            }

            <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw", backgroundColor: "#d9d9d9" }}>
                <Text style={{ fontSize: '11px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>Documentos Relacionados</Text>
            </View>

            {
                documentosRelacionados.map(doc => {
                    return <View style={{ marginTop: 0 }} wrap={false}>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                            <View style={{ width: '35%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Número de Documento</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Serie</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Folio</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Moneda</Text>
                                </View>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Tipo de Cambio</Text>
                                </View>
                            </View>


                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }} wrap={false}>
                            <View style={{ width: '35%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{doc['$']['IdDocumento']}</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{doc['$']['Serie']}</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{doc['$']['Folio']}</Text>
                                </View>
                            </View>
                            <View style={{ width: '7%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{doc['$']['MonedaDR']}</Text>
                                </View>
                            </View>
                            <View style={{ width: '12%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{TipoCambioP}</Text>
                                </View>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 0, width: "95vw" }}>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>No. Parcialidad</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Saldo Anterior</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Importe Pagado</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={styles.sectionClientePagaHeader}>
                                    <Text style={{ fontSize: '9px', fontWeight: 'bold', marginLeft: 1, position: "relative", margin: 'auto' }}>Saldo Insoluto</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', marginBottom: 10, width: "95vw" }} wrap={false}>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{doc['$']['NumParcialidad']}</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{formatoMoneda(doc['$']['ImpSaldoAnt'])}</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{formatoMoneda(doc['$']['ImpPagado'])}</Text>
                                </View>
                            </View>
                            <View style={{ width: '15%', height: "auto" }}>
                                <View style={{ weight: "auto" }}>
                                    <Text style={{ fontSize: '8px', fontWeight: 'bold', position: "relative", margin: 'auto' }}>{formatoMoneda(doc['$']['ImpSaldoInsoluto'])}</Text>
                                </View>
                            </View>
                        </View>

                    </View>
                })
            }

            <View style={{ flexDirection: 'col', marginTop: 10, width: "95vw" }} wrap={false} >
                <View style={{ flexDirection: 'row', marginTop: 10, width: "95vw" }} wrap={false} >
                    <View style={{ flexDirection: 'row', marginTop: 10, width: "25vw" }}>
                        {datosTimbre && <Image style={{ marginLeft: "0px", width: '100%', height: "auto" }} src={`https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/qrgeneratorcomplementopago?content=${dataqr}`}></Image>
                        }
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10, width: "70vw" }}>
                        <View style={styles.sectionSellosBottomFirst}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del CFDI</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{sellocfdi.match(/.{1,98}/g).join('\n')}</Text>

                        </View>
                        <View style={styles.sectionSellosBottom}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}>Sello Digital del SAT</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{sellosat.match(/.{1,98}/g).join('\n')}</Text>

                        </View>
                        <View style={styles.sectionSellosBottomLast}>
                            <View style={styles.sectionClientePagaHeader}>
                                <Text style={{ fontSize: '8px', fontWeight: 'bold', margin: 'auto' }}>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACION DIGITAL DEL SAT</Text>
                            </View>
                            <Text style={{ fontSize: '7px', marginLeft: 1 }}>{cadenaOriginal.match(/.{1,98}/g).join('\n')}</Text>

                        </View>
                    </View>


                </View>
                <View style={{ width: '95vw' }}>
                    <Text style={{ fontSize: '8px', fontWeight: 'nomal', margin: 'auto' }}>Este documento es una representación impresa de un CFDI</Text>
                    <Text style={{ fontSize: '9px', fontWeight: 'bold', margin: 'auto' }}> Todos los derechos reservados, Prohibida su copia, reproducción total o parcial sin autorización.</Text>
                    <Text style={{ fontSize: '8px', fontWeight: 'normal', margin: 'auto' }}> https://home.transportplustms.com </Text>
                </View>
            </View>



        </Page>
    </Document>;
};

const PDFView = props => {
    const params = useParams();
    const locations = useLocation();
    const [id, setId] = useState(params.id || null);
    //console.log(locations);
    const [objData, setObjData] = useState(null);
    let queryparams = queryString.parse(locations.search);
    const [showTarifa, setShowTarifa] = useState(queryparams.t);

    const getData = async () => {
        let r = null;
        if (id) {
            console.log("getting viaje");
            await getIngresoById(id).then(resp => {
                r = { id, ...resp.data() };

            }).catch(err => {

            });
            if (r.clienteId) {
                await getClienteByID(r.clienteId).then(resp => {
                    let clienteinfo = resp.data();
                    r = { ...r, clienteinfo };
                }).catch(err => { });
            }

            await getUserByUid(r.uid).then(snapshot => {
                let usuarioInfo = {};
                snapshot.forEach(document => {
                    usuarioInfo = document.data();
                    usuarioInfo.id = document.id;
                })

                r = { ...r, usuarioInfo };
            }).catch(err => { });

            if (r.usuarioInfo && queryparams.rfc) {
                if (r.usuarioInfo.razones && r.usuarioInfo.razones.length > 0) {
                    let credenciales = null;
                    r.usuarioInfo.razones.forEach(element => {
                        if (element.Rfc === queryparams.rfc) {
                            credenciales = element;
                        }
                    });

                    if (credenciales) {
                        r.usuarioInfo.credenciales = credenciales;
                    }
                }
            } else {
                console.log("NO USUARIO INFO");
            }
            let datatimbrado = await asyncparser(r);
            if (datatimbrado) {
                r.datatimbrado = datatimbrado;
            }


            /* if (certificadoFolio) {
           //      r.certificadoFolio = certificadoFolio;
             } else {
                 r.certificadoFolio = "";
             }*/
            r.fe = "";

            if (r.facuturado) {
                let fetemp = r.facuturado.selloCFD || "";
                if (fetemp.length > 6) {
                    let fefix = fetemp.slice(id.length - 5);
                    r.fe = fefix;
                }

                console.log(); //Outputs: Tabs1
                r.showTarifa = r.facuturado.mostrarTarifa || false;
            } else {
                r.showTarifa = parseBooleans(showTarifa);
            }

            // console.log("r", r);
            setObjData(r);
        }

    }

    useEffect(() => {
        // console.log("num a letra",NumeroALetras(12022.60,false));
        console.log("getting data");
        getData();
    }, []);

    const asyncparser = async (obj) => {
        if (obj.complementos && obj.complementos.length > 0) {
            let xmlstring = obj.complementos[0].timbreXML;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = objdataxml;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            let xmlstring2 = obj.complementos[0].cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml2 = xmlstring2.split('cfdi:').join('');
            fixedXml2 = xmlstring2.split('$').join('obj');
            await xml2js.parseString(fixedXml2, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    let objdataxml = result["cfdi:Comprobante"]["$"];
                    let UsoCFDI = result["cfdi:Comprobante"]["cfdi:Receptor"][0]["$"]["UsoCFDI"];
                    let Conceptos = result["cfdi:Comprobante"]["cfdi:Conceptos"];
                    let Complemento = result["cfdi:Comprobante"]["cfdi:Complemento"];
                    let Impuestos = result["cfdi:Comprobante"]["cfdi:Impuestos"];

                    let DatCCP = result["cfdi:Comprobante"]["cfdi:Complemento"][0]['cartaporte20:CartaPorte'];
                    console.log("DataCCP", DatCCP);

                    let dataAutotransporte = null;

                    if (DatCCP && DatCCP[0] && DatCCP[0]['cartaporte20:Mercancias'] && DatCCP[0]['cartaporte20:Mercancias'][0]['cartaporte20:Autotransporte']) {
                        dataAutotransporte = DatCCP[0]['cartaporte20:Mercancias'][0]['cartaporte20:Autotransporte'][0];
                    }

                    let relacionados = null;

                    if (result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"] && result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0] && result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['$']) {
                        let tipoRelacion = result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['$']['TipoRelacion']
                        let auxrelacionados = [];
                        result["cfdi:Comprobante"]["cfdi:CfdiRelacionados"][0]['cfdi:CfdiRelacionado'].forEach(rel => {
                            //  console.log('rel',rel);
                            auxrelacionados.push({ tiporelacion: tipoRelacion, uuid: rel['$']['UUID'] });
                        });
                        if (auxrelacionados.length > 0) {
                            relacionados = auxrelacionados;
                        }
                        //console.log("RELACIONADOS",auxrelacionados);
                    }

                    // console.log("data conceptos", Conceptos)
                    // console.log("segundo xml", result);
                    if (response) {
                        response = { ...response, ...objdataxml, UsoCFDI, Conceptos, Complemento, Impuestos, dataAutotransporte, relacionados };
                    }
                    //response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            //  console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    useEffect(() => {
        if (objData) {
            // console.log("data to pdf",objData);
        }
    }, [objData])

    /**
     *  <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download now!'
          }
        </PDFDownloadLink>
     <PDFViewer fileName="myPdf.pdf" style={{ width: '100vw', height: '99vh' }}>
                        <CartaPorte objdata={objData} algo="hola mundo" />
                    </PDFViewer>
    
     */

    return (
        <div>
            {

                objData &&
                <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                    <CartaPorte objdata={objData} algo="hola mundo" />
                </PDFViewer>
            }
        </div>);

}

export default PDFView;