import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import InputMask from 'react-input-mask';

const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGO_ESTADOS_USA = [
        { estado: '', estado_short: '' },
        { estado: 'Alabama', estado_short: 'AL' },
        { estado: 'Alaska', estado_short: 'AK' },
        { estado: 'Arizona', estado_short: 'AZ' },
        { estado: 'Arkansas', estado_short: 'AR' },
        { estado: 'California', estado_short: 'CA' },
        { estado: 'Carolina del Norte', estado_short: 'NC' },
        { estado: 'Carolina del Sur', estado_short: 'SC' },
        { estado: 'Colorado', estado_short: 'CO' },
        { estado: 'Connecticut', estado_short: 'CT' },
        { estado: 'Dakota del Norte', estado_short: 'ND' },
        { estado: 'Dakota del Sur', estado_short: 'SD' },
        { estado: 'Delaware', estado_short: 'DE' },
        { estado: 'Florida', estado_short: 'FL' },
        { estado: 'Georgia', estado_short: 'GA' },
        { estado: 'Hawái', estado_short: 'HI' },
        { estado: 'Idaho', estado_short: 'ID' },
        { estado: 'Illinois', estado_short: 'IL' },
        { estado: 'Indiana', estado_short: 'IN' },
        { estado: 'Iowa', estado_short: 'IA' },
        { estado: 'Kansas', estado_short: 'KS' },
        { estado: 'Kentucky', estado_short: 'KY' },
        { estado: 'Luisiana', estado_short: 'LA' },
        { estado: 'Maine', estado_short: 'ME' },
        { estado: 'Maryland', estado_short: 'MD' },
        { estado: 'Massachusetts', estado_short: 'MA' },
        { estado: 'Míchigan', estado_short: 'MI' },
        { estado: 'Minnesota', estado_short: 'MN' },
        { estado: 'Misisipi', estado_short: 'MS' },
        { estado: 'Misuri', estado_short: 'MO' },
        { estado: 'Montana', estado_short: 'MT' },
        { estado: 'Nebraska', estado_short: 'NE' },
        { estado: 'Nevada', estado_short: 'NV' },
        { estado: 'Nueva Jersey', estado_short: 'NJ' },
        { estado: 'Nueva York', estado_short: 'NY' },
        { estado: 'Nuevo Hampshire', estado_short: 'NH' },
        { estado: 'Nuevo México', estado_short: 'NM' },
        { estado: 'Ohio', estado_short: 'OH' },
        { estado: 'Oklahoma', estado_short: 'OK' },
        { estado: 'Oregón', estado_short: 'OR' },
        { estado: 'Pensilvania', estado_short: 'PA' },
        { estado: 'Rhode Island', estado_short: 'RI' },
        { estado: 'Tennessee', estado_short: 'TN' },
        { estado: 'Texas', estado_short: 'TX' },
        { estado: 'Utah', estado_short: 'UT' },
        { estado: 'Vermont', estado_short: 'VT' },
        { estado: 'Virginia', estado_short: 'VA' },
        { estado: 'Virginia Occidental', estado_short: 'WV' },
        { estado: 'Washington', estado_short: 'WA' },
        { estado: 'Wisconsin', estado_short: 'WI' },
        { estado: 'Wyoming', estado_short: 'WY' },


    ];

    const CATALOGO_REGIMENESFISCALES = [
        {
            "codigo": "622",
            "descripcion": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "606",
            "descripcion": "Arrendamiento",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "609",
            "descripcion": "Consolidación",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "624",
            "descripcion": "Coordinados",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "629",
            "descripcion": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "608",
            "descripcion": "Demás ingresos",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "630",
            "descripcion": "Enajenación de acciones en bolsa de valores",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "601",
            "descripcion": "General de Ley Personas Morales",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "628",
            "descripcion": "Hidrocarburos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "621",
            "descripcion": "Incorporación Fiscal",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "611",
            "descripcion": "Ingresos por Dividendos (socios y accionistas)",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "614",
            "descripcion": "Ingresos por intereses",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "623",
            "descripcion": "Opcional para Grupos de Sociedades",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "612",
            "descripcion": "Personas Físicas con Actividades Empresariales y Profesionales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "603",
            "descripcion": "Personas Morales con Fines no Lucrativos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "607",
            "descripcion": "Régimen de Enajenación o Adquisición de Bienes",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "625",
            "descripcion": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "615",
            "descripcion": "Régimen de los ingresos por obtención de premios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "610",
            "descripcion": "Residentes en el Extranjero sin Establecimiento Permanente en México",
            "campo1": "Sí",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "616",
            "descripcion": "Sin obligaciones fiscales",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "620",
            "descripcion": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            "campo1": "No",
            "campo2": "Sí",
            "campo3": null,
            "campo4": null
        },
        {
            "codigo": "605",
            "descripcion": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        },{
            "codigo": "626",
            "descripcion": "Régimen Simplificado de Confianza",
            "campo1": "Sí",
            "campo2": "No",
            "campo3": null,
            "campo4": null
        }
    ];

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [nombre, setNombre] = useState("");
    const [rfc, setRFC] = useState("");
    const [password, setPassword] = useState("");
    const [cerB64, setCerB64] = useState("");
    const [keyB64, setKeyB64] = useState("");

    //datos domicilio
    const [internacional, setInternacional] = useState(false);
    const [estado, setEstado] = useState();
    const [estadoSat, setEstadoSat] = useState();
    const [estado_short, setEstadoShort] = useState();
    const [localidad, setLocalidad] = useState("");
    const [localidadSat, setLocalidadSat] = useState("");
    const [municipio, setMunicipio] = useState("");
    const [municipioSat, setMunicipioSat] = useState("");
    const [calle, setCalle] = useState("");
    const [numero, setNumero] = useState("");
    const [colonia, setColonia] = useState("");
    const [coloniaSat, setColoniaSat] = useState("");
    const [colonias, setColonias] = useState([]);
    const [regimenFiscalDescripcion, setRegimenFiscalDescripcion] = useState("601 - General Ley de las Personas Morales");
    const [regimenFiscal, setRegimenFIscal] = useState("601");
    const [cp, setCP] = useState("");

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);
        //setRegimenFiscalDescripcion("601 - General Ley de las Personas Morales");
        setRegimenFIscal("601");


    }, []);



    const initValues = async () => {
        setRegimenFIscal("601");
       // setRegimenFiscalDescripcion("601 - General Ley de las Personas Morales");

        if (isEdit && recordedit != null) {

        } else {

        }
    }

    useEffect(() => {

        setUid(props.uid);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

    }, [props]);

    useEffect(() => {
        if (recordedit) {

            initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (!internacional) {
            const c = colonias.find(element => element.claveColonia === coloniaSat);
            if (c) {
                setColonia(c.colonia);
            }
        }
    }, [coloniaSat]);

    useEffect(()=>{
        if(regimenFiscal){
           let finded= CATALOGO_REGIMENESFISCALES.find(element=>element.codigo===regimenFiscal);
           if(finded){
               setRegimenFiscalDescripcion(`${finded.codigo} - ${finded.descripcion}`);
           }
        }
    },[regimenFiscal]);

    const handleSumbit = async (e) => {
        e.preventDefault();

        console.log("submiting");
        if (!ValidaRFC(rfc)) {
            Swal.fire("RFC incorrecto", "El formato de RFC no coincide con el de una persona moral, física o RFC generico ", 'error');
            return;
        }

        if (!cerB64 || cerB64 === "" || !keyB64 || keyB64 === "") {
            Swal.fire("CSD no cargado", "Debes cargar los archivos .cer y .key de tu CSD ", 'error');
            return;
        }
        setIsLoading(true);
        const direccion = {
            localidad,
            localidadSat,
            calle,
            cp,
            regimenFiscalDescripcion,
            municipio,
            municipioSat,
            internacional,
            colonia,
            coloniaSat,
            numero,
            estado,
            estadoSat,
            estado_short
        }
        //console.log(direccion);
       // return;
        await axios.post("https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/csd", { uid,regimenFiscalDescripcion, cer: cerB64, key: keyB64, password, rfc, razon: nombre, direccion }).then(resp => {
            props.toggle();
            Swal.fire("CSD registrado", "Se ha registrado el CSD de manera correcta ", 'success');
            console.log("resp", resp);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Incorrecto", "Las credenciales son incorrectas, verifica tu rfc, archivos CSD y contraseña", 'error');
            }
            setIsLoading(false);
        });
    }

    const truncateString = (str, n) => {
        return (str.length > n) ? str.substr(0, n - 1) + '... ' : str;
    };

    const onDropHandlerKey = (files) => {
        var file = files[0]
        const reader = new FileReader();
        reader.onload = (event) => {
            let b64string = '';
            let splitresult = event.target.result.split(",");
            if (splitresult.length > 1) {
                setKeyB64(splitresult[1]);
            } else {
                return setKeyB64("");
            }
            //console.log(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    function SelectorKey(props) {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: '.key',
            onDrop: files => onDropHandlerKey(files)
        });
        const files = acceptedFiles.map(file => (
            <li key={file.path}>
                {truncateString(file.path, 30) + '.key'} - {file.size} bytes
            </li>
        ));

        return (
            <section className="container">
                <div {...getRootProps({ className: 'dropzone' })} >
                    <input form="fakeForm" name="keyfile" {...getInputProps()} />
                    <p>Arrastra el archivo .key aquí, o da click para seleccionar el archivo</p>
                </div>
                <aside>
                    <h4>.key</h4>
                    <ul>{files}</ul>
                </aside>
            </section>
        );
    }



    const onDropHandlerCer = (files) => {
        var file = files[0]
        const reader = new FileReader();
        reader.onload = (event) => {
            let b64string = '';
            let splitresult = event.target.result.split(",");
            if (splitresult.length > 1) {
                setCerB64(splitresult[1]);
            } else {
                return setCerB64("");
            }
            //console.log(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    function SelectorCer(props) {
        const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
            accept: '.cer',
            onDrop: files => onDropHandlerCer(files)
        });
        const files = acceptedFiles.map(file => (
            <li key={file.path}>
                {truncateString(file.path, 30) + '.cer'} - {file.size} bytes
            </li>
        ));

        return (
            <section className="container">
                <div {...getRootProps({ className: 'dropzone' })} >
                    <input form="fakeForm" name="cerfile" {...getInputProps()} required />
                    <p>Arrastra el archivo .cer aquí, o da click para seleccionar el archivo</p>
                </div>
                <aside>
                    <h4>.cer</h4>
                    <ul>{files}</ul>
                </aside>
            </section>
        );
    }
    // patron del RFC, persona moral
    const _rfc_pattern_pm = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
    // patron del RFC, persona fisica
    const _rfc_pattern_pf = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";

    const ValidaRFC = (rfc) => {
        if (rfc.match(_rfc_pattern_pm) || rfc.match(_rfc_pattern_pf)) {
            return true;
        } else {
            return false;
        }
    }
    const changeEstadoUSA = value => {
        let obj_estado = CATALOGO_ESTADOS_USA.find(element => element.estado === value);
        if (obj_estado) {
            setEstado(obj_estado.estado);
            setEstadoShort(obj_estado.estado_short);
        }
    }
    useEffect(() => {

        if (!internacional && cp.split('_').join('').length >= 5) {
            consultarInfoFromCP();
        }
    }, [cp]);
    const consultarInfoFromCP = async () => {
        setIsLoading(true);
        await axios.get(`https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/cpostal?cp=${cp}`).then(resp => {
            // console.log("respuesta", resp.data);
            if (resp.data && resp.data.length > 0) {
                setLocalidad(resp.data[0].localidad);
                setMunicipio(resp.data[0].municipio);
                setEstado(resp.data[0].estado);
                setEstadoShort(resp.data[0].claveEstado);
                setMunicipioSat(resp.data[0].claveMunicipio);
                setLocalidadSat(resp.data[0].claveLocalidad);
                let coloniasAux = resp.data.map(element => {
                    return { claveColonia: element.claveColonia, colonia: element.colonia }
                });
                setColonias([{ claveColonia: "", colonia: "" }, ...coloniasAux]);
                console.log("datos ", resp.data);
            }
            setIsLoading(false);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo consultar", 'error');
            }
        });
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "" : `Configuración de la cuenta`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <h1>Datos de facturación</h1><br />

                    <div className="row">

                        <div className="form-group col-3">
                            <label>Nombre/Razón social </label><label className="requiredlabel">*</label>
                            <input required autoComplete="off" type="text" name='marca' className="form-control" value={nombre} onChange={e => setNombre(e.target.value)} />
                        </div>
                        <div className="form-group col-3">
                            <label>RFC </label><label className="requiredlabel">*</label>
                            <input required autoComplete="off" type="text" name='marca' className="form-control" value={rfc} onChange={e => setRFC(e.target.value.toUpperCase())} />
                        </div>
                        <div className="form-group col-5">
                            <label>Regimen Fiscal </label><label className="requiredlabel">*</label>
                            <select className="form-control" name='regimenfiscakl' onChange={(e) => setRegimenFIscal(e.target.value)} value={regimenFiscal} required >
                                {
                                    CATALOGO_REGIMENESFISCALES.map((item, index) => {
                                        return <option key={index} value={`${item.codigo}`}>{`${item.codigo} - ${item.descripcion}`}</option>
                                    })
                                }
                            </select>
                        </div>

                    </div>
                    <h1>Carga de archivos CSD </h1><br />
                    <div className="row">
                        <div className="form-group col-6">
                            <h4>Cargar archivo .cer</h4>
                            <SelectorCer />
                        </div>
                        <div className="form-group col-6">
                            <h4>Cargar archivo .key</h4>
                            <SelectorKey />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <label>Contraseña de CSD </label><label className="requiredlabel">*</label>
                            <input required autoComplete="off" type="password" name='marca' className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <label>C.P.</label><label className="requiredlabel">*</label>
                            <InputMask className={"form-control "} mask="99999" maskChar="_" value={cp} onChange={e => setCP(e.target.value)} />


                        </div>


                        <div className="form-group col-3">
                            <label> <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item style={{ fontSize: '14px' }}>MX</Grid>
                                    <Grid item>
                                        <AntSwitch checked={internacional} onChange={() => setInternacional(!internacional)} name="checkedC" />
                                    </Grid>
                                    <Grid item style={{ fontSize: '14px' }}>USA &nbsp;&nbsp;&nbsp;Estado</Grid>
                                </Grid>
                            </Typography></label>
                            {
                                internacional ?
                                    <select className="form-control" name='tipo' onChange={(e) => changeEstadoUSA(e.target.value)} value={estado} required >
                                        {
                                            CATALOGO_ESTADOS_USA.map((item, index) => {
                                                return <option key={index} value={item.estado}>{item.estado}</option>
                                            })
                                        }
                                    </select> :
                                    <input type="text" className="form-control" disabled value={estado} onChange={(e) => console.log(e.target.value)} />
                            }
                        </div>
                        {!internacional && <div className="form-group col-3">
                            <label>Municipio</label><label className="requiredlabel">*</label>
                            <input type="text" className="form-control" disabled value={municipio} onChange={(e) => console.log(e.target.value)} />
                        </div>}


                    </div>
                    <div className="row">
                        <div className="form-group col-3">
                            <label>Localidad</label><label className="requiredlabel">*</label>
                            {internacional ?
                                <input type="text" minlength="1" name='localidad' className="form-control" value={localidad} onChange={e => setLocalidad(e.target.value)} />
                                :
                                <input type="text" className="form-control" disabled value={localidad} onChange={(e) => console.log(e.target.value)} />
                            }

                        </div>

                        <div className="form-group col-3">
                            <label>Colonia</label><label className="requiredlabel">*</label>
                            {internacional ? <input required type="text" name='colonia' className="form-control" value={colonia} onChange={e => setColonia(e.target.value)} />
                                :
                                <select className="form-control" name='tipo' onChange={(e) => setColoniaSat(e.target.value)} value={coloniaSat} required >
                                    {
                                        colonias.map((item, index) => {
                                            return <option key={index} value={item.claveColonia}>{item.colonia}</option>
                                        })
                                    }
                                </select>
                            }
                        </div>
                        <div className="form-group col-3">
                            <label>Calle</label><label className="requiredlabel">*</label>
                            <input required type="text" minlength="1" name='cliente' className="form-control" value={calle} onChange={e => setCalle(e.target.value)} />
                        </div>
                        <div className="form-group col-2">
                            <label>Número</label><label className="requiredlabel">*</label>
                            <input required type="text" name='cliente' className="form-control" value={numero} onChange={e => setNumero(e.target.value)} />
                        </div>

                    </div>


                    <hr />
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;