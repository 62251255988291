import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID, getClientes, updateConfiguracionCliente } from '../../../controllers/clientes';
import { getUbicaciones } from '../../../controllers/ubicaciones';
import { getFacturaArchivos } from '../../../controllers/facturama';
import { getViajeRepo } from '../../../controllers/viajes';
import { getCfdiById } from '../../../controllers/innvoices';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import xml2js from 'xml2js';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from 'react-currency-input';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
    },
    paperrootfull: {
        width: '100%',
        margin: '5px',
        padding: '30px',
        display: 'flex'
    },

    paperroot: {
        width: '33%',
        display: 'flex'
    },
    paper: {
        minHeight: 80,
        height: 'auto',
        width: '100%',
    },
    paperscontainer: {
        // width: '100%'
        //overflow: 'hidden'
        flex: 1
    },
    title: {
        height: 30,
        backgroundColor: '#dcdcdc',
        paddingLeft: 10,
        paddingTop: 5,
    },
    label: {
        fontSize: '12px',
        paddingLeft: 3,
        paddingTop: 7,
        width: '38%',
    },

    input: {
        border: '1px solid var(--lines-clr)',
        borderRadius: '5px',
        width: '90%'
    },
    inputbig: {
        width: '100%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputsmall: {
        display: 'inline-block',
        float: 'right',
        width: '50%',
        height: 30,
        marginTop: 4,
        marginRight: 2
    },
    inputwithextra: {
        display: 'inline-block',
        float: 'right',
        width: '30%',
        height: 30,
        marginRight: 2
    },
    inputdate: {
        width: '99%',
        marginTop: 4,
        marginLeft: 0,
        marginRight: 2,
        height: '30px',
    }
}));
const ModalCreateEdit = (props) => {
    const classes = useStyles();
    //constantes
    const conceptos = [
        { id: "1", clave: "78101800", descripcion: "Transporte de carga por carretera" },
        { id: "2", clave: "78101801", descripcion: "Servicios de transporte de carga por carretera (en camión) en área local" },
        { id: "3", clave: "78101802", descripcion: "Servicios transporte de carga por carretera (en camión) a nivel regional y nacional" },
        { id: "4", clave: "78101806", descripcion: "Servicios transporte de carga por carretera a nivel internacional" },
        { id: "5", clave: "78101807", descripcion: "Servicios transporte de carga de petróleos o químicos" },
        { id: "24", clave: "78141500", descripcion: "Movimiento de fletes" },
        { id: "31", clave: "78141500", descripcion: "Coordinación de logística en transporte" },
        { id: "6", clave: "78141501", descripcion: "Coordinación de fletes" },
        { id: "7", clave: '95111602', descripcion: 'Carretera o autopista de peaje' },
        { id: "8", clave: '78121602', descripcion: 'Servicios de pesaje' },
        { id: "9", clave: '78121601', descripcion: 'Carga y descarga de mercancías' },
        { id: "10", clave: '78141902', descripcion: 'Estadia/Demoras' },
        { id: "11", clave: '78101802', descripcion: 'Reparto o recolección' },
        { id: "12", clave: '78111808', descripcion: 'Alquiler de vehículos' },
        { id: "13", clave: '78101805', descripcion: 'Servicio de alquiler de remolques o camiones cisterna' },
        { id: '14', clave: '78101801', descripcion: 'Paradas o recolecciones' },
        { id: '15', clave: '78121502', descripcion: 'Sello Y Flejado' },
        { id: '16', clave: '84131504', descripcion: 'Seguros de carga' },
        { id: '17', clave: '92121504', descripcion: 'Custodia' },
        { id: '18', clave: '78101802', descripcion: 'Servicio de GPS' },
        { id: '19', clave: '78101802', descripcion: 'Servicio express' },
        { id: '20', clave: '78101806', descripcion: 'Servicio especial' },
        { id: '21', clave: '78141902', descripcion: 'Alquiler de contenedores cerrados' },
        { id: '22', clave: '78121600', descripcion: 'Servicio de manejo de materiales' },
        { id: '23', clave: '78131803', descripcion: 'Almacenaje de materiales peligrosos' },
        { id: "25", clave: "76111801", descripcion: "Limpieza de vehículos" },
        { id: "26", clave: '78101805', descripcion: 'Renta de Cajas' },
        { id: "27", clave: '25101611', descripcion: 'Camiones de Carga' },
        { id: "34", clave: '25102100', descripcion: 'Camiones tractores' },
        { id: "28", clave: '25181714', descripcion: 'Semi - remolque' },
        { id: "29", clave: '78111809', descripcion: 'Leasing de vehículos sedán, cupé o camioneta' },
        { id: "30", clave: '76111800', descripcion: 'Limpieza de vehículos de transporte' },
        { id: "32", clave: '78131700', descripcion: 'Estadia /Demoras' },
        { id: "33", clave: '25101500', descripcion: 'Vehiculos de pasajeros' },
        { id: "35", clave: '25172406', descripcion: 'Tanques de combustible' },
        { id: "36", clave: '25101602', descripcion: 'Remolques Dolly' },
        { id: "37", clave: '81141601', descripcion: 'Logística (Maniobras terrestres, aéreas y marítimas)' },
        { id: '38', clave: '95111600', descripcion: 'Peaje' },
        { id: '41', clave: '78111811', descripcion: 'Alquiler de vehículos de camión ligero' },
        { id: '39', clave: '01010101', descripcion: 'No existe en el catálago' },
    ];
    const CAT_METODOSPAGO = [
        {
            "Name": "Pago en parcialidades ó diferido",
            "Value": "PPD"
        },
        {
            "Name": "Pago en una sola exhibición",
            "Value": "PUE"
        }
    ];
    const CAT_USOCFDI = [
        {
            "Natural": true,
            "Moral": false,
            "Name": "Sin efectos fiscales.",
            "Value": "S01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Honorarios médicos, dentales y gastos hospitalarios.",
            "Value": "D01"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos médicos por incapacidad o discapacidad",
            "Value": "D02"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos funerales.",
            "Value": "D03"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Donativos.",
            "Value": "D04"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
            "Value": "D05"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Aportaciones voluntarias al SAR.",
            "Value": "D06"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Primas por seguros de gastos médicos.",
            "Value": "D07"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Gastos de transportación escolar obligatoria.",
            "Value": "D08"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
            "Value": "D09"
        },
        {
            "Natural": true,
            "Moral": false,
            "Name": "Pagos por servicios educativos (colegiaturas)",
            "Value": "D10"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Adquisición de mercancias",
            "Value": "G01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Devoluciones, descuentos o bonificaciones",
            "Value": "G02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Gastos en general",
            "Value": "G03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Construcciones",
            "Value": "I01"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Mobilario y equipo de oficina por inversiones",
            "Value": "I02"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de transporte",
            "Value": "I03"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Equipo de computo y accesorios",
            "Value": "I04"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Dados, troqueles, moldes, matrices y herramental",
            "Value": "I05"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones telefónicas",
            "Value": "I06"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Comunicaciones satelitales",
            "Value": "I07"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Otra maquinaria y equipo",
            "Value": "I08"
        },
        {
            "Natural": true,
            "Moral": true,
            "Name": "Por definir",
            "Value": "P01"
        }
    ]
    const CAT_FORMASPAGO = [
        {
            "Name": "Efectivo",
            "Value": "01"
        },
        {
            "Name": "Cheque nominativo",
            "Value": "02"
        },
        {
            "Name": "Transferencia electrónica de fondos",
            "Value": "03"
        },
        {
            "Name": "Tarjeta de crédito",
            "Value": "04"
        },
        {
            "Name": "Monedero electrónico",
            "Value": "05"
        },
        {
            "Name": "Dinero electrónico",
            "Value": "06"
        },
        {
            "Name": "Vales de despensa",
            "Value": "08"
        },
        {
            "Name": "Dación en pago",
            "Value": "12"
        },
        {
            "Name": "Pago por subrogación",
            "Value": "13"
        },
        {
            "Name": "Pago por consignación",
            "Value": "14"
        },
        {
            "Name": "Condonación",
            "Value": "15"
        },
        {
            "Name": "Compensación",
            "Value": "17"
        },
        {
            "Name": "Novación",
            "Value": "23"
        },
        {
            "Name": "Confusión",
            "Value": "24"
        },
        {
            "Name": "Remisión de deuda",
            "Value": "25"
        },
        {
            "Name": "Prescripción o caducidad",
            "Value": "26"
        },
        {
            "Name": "A satisfacción del acreedor",
            "Value": "27"
        },
        {
            "Name": "Tarjeta de débito",
            "Value": "28"
        },
        {
            "Name": "Tarjeta de servicios",
            "Value": "29"
        },
        {
            "Name": "Aplicación de anticipos",
            "Value": "30"
        },
        {
            "Name": "Intermediarios",
            "Value": "31"
        },
        {
            "Name": "Por definir",
            "Value": "99"
        }
    ]
    const CAT_TIPORELACION = [
        { value: '', descripcion: '' },
        { value: '01', descripcion: 'Notas de Crédito de Documentos Relacionados' },
        { value: '02', descripcion: 'Notas de Débito de los Documentos Relacionados' },
        { value: '03', descripcion: 'Devolución de Mercancías sobre Facturas o Traslados Previos' },
        { value: '04', descripcion: 'Sustitución de los CFDI Previos' },
        { value: '05', descripcion: 'Traslados de Mercancías Facturados Previamente' },
        { value: '06', descripcion: 'Factura Generada por los Traslados Previos' },
        { value: '07', descripcion: 'CFDI por Aplicación de Anticipo' },
        { value: '08', descripcion: 'Facturas Generadas por Pagos en Parcialidades' },
        { value: '09', descripcion: 'Factura Generada por Pagos Diferidos' }
    ]

    const requiereIVacustom = ['LOZJRkQRLvbN2mWSjX4MFNr1qvj2','31RxHqtBOmbgOWWeMCKnb1DuEHc2','vdFfOQBu8QThjB74T4aoYLigZE03','Ih4Xm02XKnS7zam8UAWSBL1671j1'];
    const requierAddenda = ['LOZJRkQRLvbN2mWSjX4MFNr1qvj2','trNNR8HTNvYC2KBwBTVbqAiOKlL2'];
    const [addendas, setDataAddendas] = useState(['No', 'Chrysler TPV']);
    const [addendaseleccionada, setAddendaseleccionada] = useState('No');
    //addenda Chrysler
    const [dataAddendaC, setDataAddendaC] = useState({
        tipoDocumento: 'TPV',
        TipoDocumentoFiscal: 'FA',//FA factura CP cart porte
        ReferenciaProveedor: '',
        CveProveedor: '',
        Proveedor: '',
        CveOrigen: '',
        Origen: '',
        CveDestino: '',
        Destino: '',
        CveReciving: '',
        Reciving: '',
        Cancelaciones: [],
        Notas: []

    });

    //estados de control de modal y registro
    const [conceptosData, setConceptosData] = useState(conceptos);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);

    const [chekingCSD, setCheckinCSD] = useState(true);

    const [nviaje, setNviaje] = useState("");
    const [ncarta, setNcarta] = useState("");

    const [clienteData, setClietneData] = useState(null);
    const [isIVA16, setIsIVA16] = useState(true);
    const [facturaArchivos, setFacturaArchivos] = useState(null);
    const [isFacuturaCancelada, setIsFacturaCancelada] = useState(false);
    const [ivatasa, setIvaTasa] = useState("16");
    const [retencion, setRetencion] = useState("0");
    const [razonesSociales, setRazonesSociales] = useState([]);
    const [rfc, setRfc] = useState("");

    const [referencia, setReferencia] = useState("");
    const [comentarios, setComentarios] = useState("");

    const [version, setVersion] = useState("4.0");
    const [isViaje, setIsVIaje] = useState("0");
    const [usoCfdi, setUsoCfdi] = useState("P01 - Por definir");
    const [claveConcepto, setClaveConcepto] = useState("78101800");

    const [metodoPago, setMetodoPago] = useState("PUE - Pago en una sola exhibición");
    const [formaPago, setFormaPago] = useState("99 - Por definir");
    const [condicionesPago, setCondicionesPago] = useState("");
    const [dolarprice, setDolarPrice] = useState(20.0);
    const [isDLL, setIsDLL] = useState(false);
    const [moneda, setMoneda] = useState("MXN");
    const [extras, setExtras] = useState([]);
    const [existeRelacion, setExisteRelacion] = useState("No");
    const [tipoRelacion, setTipoRelacion] = useState("");
    const [UIDRelacion, setUidRelacion] = useState("");
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [usuarioobj, setUsuarioObj] = useState(null);

    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState("");
    const [clienteId, setClienteId] = useState("");

    const [isPublicoEnGeneral, setIsPublicoEnGeneral] = useState(false);
    const [mesPeriodicidad, setMesPeriodicidad] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"));
    const [yearPeriodicidad, setYearPeriodicidadd] = useState("" + new Date().getFullYear());

    const [localidades, setLocalidades] = useState([]);
    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState("");
    const [destinoId, setDestinoId] = useState("");
    const [origenMunicipioSat, setOrigenMunicipioSat] = useState("");
    const [origenMunicipio, setOrigenMunicipio] = useState("");
    const [origenColonia, setOrigenColonia] = useState("");
    const [origenColoniaSat, setOrigenColoniaSat] = useState("");
    const [origenCiudadSat, setOrigenCiudadSat] = useState("");
    const [origenCiudad, setOrigenCiudad] = useState("");
    const [origenEstado, setOrigenEstado] = useState("");
    const [origenEstadoShort, setOrigenEstadoShort] = useState("");
    const [destinoMunicipioSat, setDestinoMunicipioSat] = useState("");
    const [destinoMunicipio, setDestinoMunicipio] = useState("");
    const [destinoColonia, setDestinoColonia] = useState("");
    const [destinoColoniaSat, setDestinoColoniaSat] = useState("");
    const [destinoCiudadSat, setDestinoCiudadSat] = useState("");
    const [destinoCiudad, setDestinoCiudad] = useState("");
    const [destinoEstado, setDestinoEstado] = useState("");
    const [destinoEstadoShort, setDestinoEstadoShort] = useState("");

    const [partidas, setPartidas] = useState([]);
    const [ntibmres, setNtimbres] = useState(0);


    const GetUbicacionesServer = async () => {
        console.log("getting clientes de server");
        await getUbicaciones(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let num = item.numero || "";
                let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                idubica = idubica.trim();
                let ob = { ...item, name: idubica, value: idubica };
                return ob;
            });
            setLocalidades(toset);
        }).catch(err => { });

    }
    const GetClientesServer = async () => {
        console.log("getting clientes de server");

        await getClientes(uid).then(snapshot => {
            let aux = [];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            let toset = aux.map((item, index) => {
                let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "user" + index, configuracionCFDI: item.configuracionCFDI };
                return ob;
            });

            setClientes(toset);

        }).catch(err => { });
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {
        if (user && user.razones) {

            let razonesasignar = [];
            user.razones.forEach(r => {
                if (!r.inactivo) {
                    razonesasignar.push(r);
                }
            })
            setRazonesSociales(razonesasignar);

            if (user.razones.length > 0 && user.razones[0].Rfc) {
                setRfc(user.razones[0].Rfc);
            }
        }
    }, [user]);

    const checkCSD = async () => {
        console.log("cheking csd", uid);
        if (uid) {
            await getViajeRepo(uid).then(resp => {
                let nt = 0;
                resp.forEach(document => {
                    let o = document.data();
                    if (o && o.ntimbres && nt <= o.ntimbres) {
                        nt = o.ntimbres;
                    }
                });
                setNtimbres(nt);
            }).catch(err => { });
            getUserByUid(uid).then(resp => {
                let obuser = null;
                resp.forEach(document => {
                    obuser = { ...document.data(), id: document.id }

                });
                setUser(obuser);
                if (obuser.dolarprice) {
                    setDolarPrice(obuser.dolarprice);
                }
                setCheckinCSD(false);
                setUsuarioObj(obuser);
                //console.log("datos usuario",resp.docs[0].data());
                if (resp.docs.length > 0 && resp.docs[0].data().csd) {
                    initValues();
                } else {
                    Swal.fire("No CSD", "No se han cargado los CSD para esta cuenta, da de alta primero tus credenciales de facturacion en la seccion de dashboard -> configuraciones ", 'warning');
                    setIsLoading(false);
                    setIsOpen(false);
                }
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar CSD, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            Swal.fire("Error", "Error sesion terminada", 'error');
            setIsLoading(false);
            setIsOpen(false);
        }

    }

    useEffect(() => {
        if (clienteId) {
            getClienteByID(clienteId).then(resp => {
                if (resp.data()) {
                    setClietneData(resp.data());
                }
            }).catch(err => { });
        }
    }, [clienteId]);

    const initValues = async () => {
        setIsLoading(true);
        setDataAddendaC({
            tipoDocumento: 'TPV',
            TipoDocumentoFiscal: 'FA',//FA factura CP cart porte
            ReferenciaProveedor: '',
            CveProveedor: '',
            Proveedor: '',
            CveOrigen: '',
            Origen: '',
            CveDestino: '',
            Destino: '',
            CveReciving: '',
            Reciving: '',
            Cancelaciones: [],
            Notas: []

        });
        if (idViaje != null) {
            await getCfdiById(idViaje).then(document => {
                console.log("llegaron datos");
                let o = document.data();
                o.id = document.id;
                o.key = document.id;
                setRecordEdit(o);
                setIsLoading(false);
            }).catch(err => { console.log(err); setIsLoading(false); });
        }
        await GetClientesServer();
        await GetUbicacionesServer();
        setIsLoading(false);
    }

    useEffect(() => {
        console.log("record edit", recordedit);
        if (recordedit != null) {

            setUsoCfdi("P01 - Por definir");
            setClaveConcepto("78101800");
            setMetodoPago("PUE - Pago en una sola exhibición");
            setFormaPago("99 - Por definir");

            setClienteId(recordedit.clienteId || "");



            //facuturado
            console.log("cheking facturado", recordedit);
            if (recordedit.facuturado) {
                setFacturaArchivos(recordedit.facuturado);
            } else {
                setFacturaArchivos(null);
            }
            if (recordedit.facturaCancelada === true) {
                // console.log("is cancelada factura",recordedit.facturaCancelada);
                setIsFacturaCancelada(true);
            } else {
                setIsFacturaCancelada(false);
            }

        } else {
            setFacturaArchivos(null);
        }
    }, [recordedit]);

    const changeRetencionExtra = (event, index) => {
        //
        let newArr = [...extras];

        if (index >= 0) {
            newArr[index].retencion = event.target.value;
            setExtras(newArr);
        }

    }

    useEffect(() => {

        setCheckinCSD(true);
        setUid(props.uid);

        /**
         * conceptos personalizados
         * adicionales
         * extras
         * por cuenta
         */
        if (props.uid === "WKJlA8I0ZMZU7XjP9FFhc8TTREF3") {
            setConceptosData([...conceptos, { id: '200', clave: '11111611', descripcion: 'Gravilla' },
                { id: '201', clave: '11111700', descripcion: 'Arena' }
            ])
        }

        //f5ti9SuBPVgdMf9rNa47mzPjmrl1  gilberto
        if (props.uid === "f5ti9SuBPVgdMf9rNa47mzPjmrl1") {
            setConceptosData([...conceptos, { id: '200', clave: '80151600', descripcion: 'Servicio internacional gastos en extranjero' }])
        }
        //jvd
        if (props.uid === "Kfv8dP1eSIXnzUQWQpCrCREEgUs2") {
            setConceptosData([...conceptos, { id: '200', clave: '80131500', descripcion: 'Renta de Espacio' },
                { id: '201', clave: '25101503', descripcion: 'Venta de automóviles usados' },
                { id: '202', clave: '78181508', descripcion: 'Mantenimiento y reparación de cajas' },
                { id: '203', clave: '25102100', descripcion: 'Camiones tractores' }
                 
            ])
        }

        if (props.uid === "Gof9aW8KeINcQaumXev52je5VH12") {
            setConceptosData([...[],
                { id: '200', clave: '86101715', descripcion: 'Consultoría en Transporte' }
                 ])
        }

        

        if (props.uid === "trNNR8HTNvYC2KBwBTVbqAiOKlL2") {
            setConceptosData([...conceptos, { id: '200', clave: '81141601', descripcion: 'Renta de Tracto' },
            { id: '201', clave: '81141601', descripcion: 'Reparaciones de cajas' },
            { id: '202', clave: '81141601', descripcion: 'Renta de cajas' }
            ]);
        }

        if (props.uid === "HptPbvVxYYUjUzp7Nt3TtmyAt0x1") {
            setConceptosData([...conceptos, { id: '200', clave: '25172500', descripcion: 'Servicio reparación de llantas a domicilio R10' },
            { id: '201', clave: '78181508', descripcion: 'Mantenimiento y reparación de camiones pesados' }])
        }

        if (props.uid === "BrZNecrFhCX9aWWjLJQy2B8EAAp2") {
            setConceptosData([...conceptos, 
                { id: '200', clave: '78181500', descripcion: 'Servicios de mantenimiento y reparación de vehículo' },
                { id: '201', clave: '78181508', descripcion: 'Mantenimiento y reparación de cajas' }])
        }
        //diego

        if (props.uid === "q14KUVeyCRSyeI30rOOTD8EbDLS2") {
            setConceptosData([...conceptos, { id: '200', clave: '25101503', descripcion: 'Carros (Automóviles)' }])
        }
        //medel
        if (props.uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1") {
            setConceptosData([...conceptos,
            { id: '200', clave: '26111703', descripcion: 'Baterías para vehículos (Acumuladores)' },
            { id: '201', clave: '78131600', descripcion: 'Almacenaje' },
            { id: '202', clave: '25172503', descripcion: 'Llantas para camiones pesados' },
            { id: '203', clave: '25172509', descripcion: 'Neumáticos para llantas de camiones pesados' },
            ])
        }
        if (props.uid === "LIv7EZL3RZhZnI3ZGpRXeehkZWA2") {
            setConceptosData([...conceptos,
            { id: '201', clave: '78131600', descripcion: 'Almacenaje' },
            ])
        }

        if (props.uid === "70NJdMGNIFQhYd8cpiAx26zHPXO2") {
            setConceptosData([...conceptos, { id: '200', clave: '81111500', descripcion: 'ASIGNACIÓN DE TIMBRES A SISTEMA TRANSPORT PLUS' },
            { id: '201', clave: '81111500', descripcion: 'LICENCIA SISTEMA TMS TRANSPORT PLUS' }])
        }

        //
        if (props.uid === "RZHc17ViPqQTIJ7VShGfbBRwXBw1") {
            setConceptosData([...conceptos, { id: '200', clave: '78181500', descripcion: 'Servicios de mantenimiento y reparación de vehículo' },
            ])
        }
        //Mo3AFqf50QgUbHbGTJvaqGXyEdn1  camacho
        if (props.uid === "Mo3AFqf50QgUbHbGTJvaqGXyEdn1") {
            setConceptosData([...conceptos, { id: '200', clave: '25101611', descripcion: 'Tractocamión para traslado de mercancía' },
            ])
        }

        if (props.uid === "MPVVo1Hr5XgmmmqdHxfqNfQgQB03") {
            setConceptosData([...conceptos,
            { id: '200', clave: '78181500', descripcion: 'Daños' },
            { id: '201', clave: '73131700', descripcion: 'Procesamiento de frutas y vegetales' },
            { id: '202', clave: '84111506', descripcion: 'Anticipo' },
            ])
        }

        if (props.uid === "Is55uxcCDdMdI53LaFtWvyFXGH03") {
            setConceptosData([...conceptos, { id: '200', clave: '80141615', descripcion: 'Vehículo usado, de alquiler o exposición' },
            { id: '201', clave: '84111506', descripcion: 'Anticipo' },
            { id: '202', clave: '24101602', descripcion: 'Montacargas' },
            { id: '203', clave: '24102210', descripcion: 'Prensa de embalar' },
            { id: '204', clave: '23152900', descripcion: 'Maquinaria empacadora' },

            ])
        }


        if (props.uid === "31RxHqtBOmbgOWWeMCKnb1DuEHc2") {
            setConceptosData([...conceptos,
            { id: '200', clave: '78101806', descripcion: 'Importación' },
            { id: '201', clave: '78101806', descripcion: 'Exportación' },
            { id: '202', clave: '78131600', descripcion: 'Almacenaje' },
            { id: '203', clave: '95111600', descripcion: 'Peaje' },
            { id: '204', clave: '78141900', descripcion: 'Demoras/Tiempo Extra' },
            { id: '205', clave: '78141900', descripcion: 'Demoras MEX' },
            { id: '206', clave: '78141900', descripcion: 'Demoras USA' },
            { id: '207', clave: '81141601', descripcion: 'Logística' },
            { id: '208', clave: '78101800', descripcion: 'Local JRZ' },
            { id: '209', clave: '78101800', descripcion: 'Local Texas' },
            { id: '210', clave: '78181500', descripcion: 'Daños' },
            { id: '211', clave: '85121810', descripcion: 'Inspección canina' },
            ])
        }

        //alrey honorarios  vdFfOQBu8QThjB74T4aoYLigZE03
        //Ih4Xm02XKnS7zam8UAWSBL1671j1
        if (props.uid === "vdFfOQBu8QThjB74T4aoYLigZE03" || props.uid === "Ih4Xm02XKnS7zam8UAWSBL1671j1") {
            setConceptosData([...conceptos,
            { id: '200', clave: '80111601', descripcion: 'Honorarios' },
            { id: '201', clave: '25101611', descripcion: 'Venta de activo' },
            { id: '202', clave: '25101503', descripcion: 'Venta de activo' },
            { id: '203', clave: '81141601', descripcion: 'Logística' },
            { id: '204', clave: '76122401', descripcion: 'Tarifa de sobreestadía' },
            { id: '205', clave: '20122807', descripcion: 'Aparejos de maniobras' },

            ])
        }


        //TCB qdFv72AbKxQTZIDoiImZ8EI6r3A3
        if (props.uid === "qdFv72AbKxQTZIDoiImZ8EI6r3A3") {
            setConceptosData([...conceptos,
            { id: '200', clave: '22101500', descripcion: 'Maquinaria para trabajo de desmonte' },
            ])
        }
        //sepedel goncaja
        //DpgnFvRr99WMzSfrVXWADeZy0R83  u3AB65PQHNea7reDJ5uEzrCB9kL2
        if (props.uid === "DpgnFvRr99WMzSfrVXWADeZy0R83" || props.uid === "u3AB65PQHNea7reDJ5uEzrCB9kL2") {
            setConceptosData([...conceptos,
            { id: '200', clave: '80141610', descripcion: 'Anticipo de servicio logístico' },

            ])
        }


        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);

        setPartidas([]);
        setMoneda("MXN");
        setCliente("");
        setOrigen("");
        setDestino("");
        setIsVIaje("0");

        setUidRelacion("");
        setTipoRelacion("");
        setExisteRelacion("No");

        //setRecordEdit(props.currenteditrecrodcfdinormal);

        if (props.currenteditrecrodcfdinormal) {
            console.log("si currentedit");
            if (props.currenteditrecrodcfdinormal.notacredito) {

            } else {
                setIdViaje(props.currenteditrecrodcfdinormal.key);
            }

        } else {
            setIdViaje(null);
            setFacturaArchivos(null);
            console.log("no currentedit");
        }
        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (idViaje) {

            initValues();
        }

    }, [idViaje]);




    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            checkCSD();
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        console.log("asignando cliente");
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name.trim() === cliente.trim());
            if (objf) {

                if (objf.rfc === "XAXX010101000" && objf.nombre === "PUBLICO EN GENERAL") {
                    setIsPublicoEnGeneral(true);
                } else {

                    console.log(objf.rfc === "XAXX010101000", objf.nombre === "PUBLICO EN GENERAL");
                    console.log("XAXX010101000", objf.rfc);
                }

                if (objf.configuracionCFDI) {

                    setUsoCfdi(objf.configuracionCFDI.usoCfdi || "P01 - Por definir");
                    setMetodoPago(objf.configuracionCFDI.metodoPago || "PUE - Pago en una sola exhibición");
                    setFormaPago(objf.configuracionCFDI.formaPago || "99 - Por definir");
                    if (objf.configuracionCFDI.iva) {
                        setIvaTasa(objf.configuracionCFDI.iva);
                    }

                } else {

                }
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                    setClietneData(objf);
                    console.log("se asigno cliente id");


                }
            } else {
                console.log("no se encontro cliente ID");
                console.log("  cliente nombre", cliente.trim());
                console.log("  clientes", clientes);
            }
        }
    }, [cliente]);

    const validaCCP = async () => {
        const errores = [];
        if (recordedit) {
            if (!recordedit.carta_porte.distBase_km || recordedit.carta_porte.distBase_km === "" || recordedit.carta_porte.distBase_km == 0) {
                errores.push("Falta registrar los Kilómetros del viaje");
            }


            if (errores.length > 0) {
                let errstrings = errores.join(",");
                Swal.fire("Viaje no valido", `Faltan datos del viaje: ${errstrings}`, 'warning');
                return false;
            }

        } else {
            Swal.fire("Viaje no valido", `No se encontraron datos del viaje`, 'warning');
            return false;
        }
        return true;
    }
    const actualizaConfiguracion = async () => {
        let auxreten = "0";
        let auxclve = "78101800";
        if (clienteData.configuracionCFDI) {
            auxreten = clienteData.configuracionCFDI.retencion || "0";
            auxclve = clienteData.configuracionCFDI.claveConcepto || "78101800";
        }
        if (clienteId) {
            let nuevaConfiguracion = {
                usoCfdi,
                formaPago,
                metodoPago,
                retencion: auxreten,
                claveConcepto: auxclve,
                iva: ivatasa
            }
            await updateConfiguracionCliente(clienteId, nuevaConfiguracion).then(resp => { console.log("configuracion actualizada") }).catch(err => { console.log(err) });
        }

    }
    const handleSumbit = async (e) => {
        e.preventDefault();
        console.log("submiting");

        if (ntibmres <= 0) {
            Swal.fire("No se puede timbrar", "No hay timbres disponibles", 'warning');
            return false;
        }
        if (partidas.length === 0) {
            Swal.fire("CFDI no válido", `Agrega al menos un concepto al CFDI`, 'warning');
            return false;
        }
        setIsLoading(true);

        let datatopost = {
            cliente,
            clienteId,
            isViaje,
            origen,
            origenId,
            destino,
            destinoId,
            metodoPago,
            formaPago,
            condicionesPago,
            moneda,
            dolarprice,
            referencia,
            comentarios,
            usoCfdi,
            existeRelacion,
            UIDRelacion,
            tipoRelacion,
            segmentoNegocio,
            ivarate: ivatasa,
            rfc,
            version,
            conceptos: partidas,
            mesPeriodicidad,
            yearPeriodicidad,
            uid
        }

        if (uid && addendaseleccionada != "No" && requierAddenda.find(rfindadd => rfindadd === uid)) {
            datatopost.addendaChrysler = { ...dataAddendaC };
        }

        if (clienteData && clienteData.configuracionCFDI) {
            if (clienteData.configuracionCFDI.usoCfdi !== usoCfdi ||
                clienteData.configuracionCFDI.formaPago !== formaPago ||
                clienteData.configuracionCFDI.metodoPago !== metodoPago ||
                clienteData.configuracionCFDI.iva !== ivatasa) {
                await actualizaConfiguracion();
            }
        } else if (clienteData) {
            await actualizaConfiguracion();
        }


        let rutaPost = "https://us-central1-transport-plus-98f66.cloudfunctions.net/app/api/";
        if (rfc === "EKU9003173C9") {
            rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";
        }

        //rutaPost = "http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";
        let rutatipofactura = "fptcfdiingresosolo";


        await axios.post(`${rutaPost}${rutatipofactura}`, datatopost).then(resp => {
            setIsLoading(false);


            if (resp.data == "Ok") {
                Swal.fire("Factura generada", `Se ha generado la factura `, 'success');
                props.notify({ status: 'ok' });
                props.toggle();
            } else {

                console.log("resp.data", resp.data);

                if (resp.data && resp.data.estatus && resp.data.estatus.informacionTecnica) {
                    Swal.fire("No se pudo generar", `${resp.data.estatus.informacionTecnica}`, 'warning');
                } else {
                    if (typeof resp.data === "object") {
                        const myJSON = JSON.stringify(resp.data);
                        Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                    } else {
                        Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                    }
                    props.notify({ status: 'fail' });
                    props.toggle();
                }

            }

            //console.log("respuesta", resp);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo generar la factura", 'error');
            }
        });
    }
    const handlePreview = async () => {

        console.log("submiting");
        if (clienteId === "" || cliente === "") {
            Swal.fire("CFDI no válido", `Selecciona el cliente`, 'warning');
            return false;
        }
        if (partidas.length === 0) {
            Swal.fire("CFDI no válido", `Agrega al menos un concepto al CFDI`, 'warning');
            return false;
        }
        setIsLoading(true);

        let datatopost = {
            cliente,
            clienteId,
            isViaje,
            origen,
            origenId,
            destino,
            destinoId,
            metodoPago,
            formaPago,
            condicionesPago,
            moneda,
            dolarprice,
            referencia,
            comentarios,
            usoCfdi,
            existeRelacion,
            UIDRelacion,
            tipoRelacion,
            segmentoNegocio,
            ivarate: ivatasa,
            rfc,
            version,
            conceptos: partidas,
            uid
        }

        let rutaPost = "https://us-central1-unit-testing-d4fe7.cloudfunctions.net/app/api/";


        // rutaPost = "http://localhost:5000/unit-testing-d4fe7/us-central1/app/api/";
        let rutatipofactura = "previewcfdi";

        await axios.post(`${rutaPost}${rutatipofactura}`, datatopost).then(resp => {
            setIsLoading(false);
            //  props.notify();
            // props.toggle();
            console.log(resp.data);
            if (resp.data.id && resp.data.id != 0) {
                let dtime = new Date().getTime();
                const win = window.open('/previewcfdi/' + resp.data.id + "?d=" + dtime, "_blank");
                win.focus();
                // Swal.fire("Factura generada", `Se ha generado la factura ${resp.data}`, 'success');
            } else {
                console.log("resp.data", resp.data);
                if (typeof resp.data === "object") {
                    const myJSON = JSON.stringify(resp.data);
                    Swal.fire("No se pudo generar", `${myJSON}`, 'warning');
                } else {
                    Swal.fire("No se pudo generar", `${resp.data}`, 'warning');
                }



            }

            //console.log("respuesta", resp);


        }).catch(err => {
            setIsLoading(false);
            console.log("error", err.toString());
            if (err.toString().includes("Network Error")) {
                Swal.fire("Error", "Error de conexión, no fue posible conectar con el servidor, revisa tu conexión", 'error');
            } else {
                Swal.fire("Error", "No se pudo generar la preview", 'error');
            }
        });
    }


    useEffect(() => {
        if (origen) {
            let origenOBJ = localidades.find(element => element.name == origen);
            if (origenOBJ) {
                setOrigenId(origenOBJ.id);
                setOrigenCiudad(origenOBJ.localidad);
                setOrigenCiudadSat(origenOBJ.localidadSat || "");
                setOrigenEstado(origenOBJ.estado);
                setOrigenEstadoShort(origenOBJ.estado_short);
                setOrigenMunicipioSat(origenOBJ.municipioSat || "");
                setOrigenMunicipio(origenOBJ.municipio || "");
                setOrigenColonia(origenOBJ.colonia || "");
                setOrigenColoniaSat(origenOBJ.coloniaSat || "");

            }
        }
        if (destino) {
            let destinoOBJ = localidades.find(element => element.name == "" + destino);
            if (destinoOBJ) {
                console.log("se encontro destino", destino);
                setDestinoId(destinoOBJ.id);
                setDestinoCiudad(destinoOBJ.localidad);
                setDestinoEstado(destinoOBJ.estado);
                setDestinoEstadoShort(destinoOBJ.estado_short);
                setDestinoMunicipioSat(destinoOBJ.municipioSat || "");
                setDestinoCiudadSat(destinoOBJ.localidadSat || "");
                setDestinoMunicipio(destinoOBJ.municipio || "");
                setDestinoColonia(destinoOBJ.colonia || "");
                setDestinoColoniaSat(destinoOBJ.coloniaSat || "");
            }
        }
    }, [origen, destino])

    const downloadPDF = (pdf) => {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const downloadXML = (pdf) => {
        const linkSource = `data:application/xml;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        let nombrefactura = "";
        if (ncarta) {
            nombrefactura = ncarta || "";
        }
        const fileName = `factura${nombrefactura}.xml`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    const verComplementoCartaPorte = async () => {
        if (recordedit && recordedit.isFormatoNuevo) {
            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                if (recordedit.dataTimbrada) {
                    const win = window.open('/ccpv2/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                } else {
                    const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                    win.focus();
                }

            }
        } else if (recordedit) {

            let datatimbrado = await asyncparser(facturaArchivos);
            console.log("datatimbrado", datatimbrado);
            let rfctimbrado = null;
            if (datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc']) {
                rfctimbrado = datatimbrado['cfdi:Comprobante']['cfdi:Emisor'][0]['$']['Rfc'];
            }
            if (rfctimbrado) {
                let dates = new Date();
                const win = window.open('/cfdi/' + recordedit.key + "?rfc=" + rfctimbrado + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
                win.focus();
            } else {
                descargarFacturaPDF();
            }
            //  descargarFacturaPDF();
        }

        // return;
        /* if (!facturaArchivos && recordedit && recordedit.key) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         } else if (facturaArchivos) {
             let dates = new Date();
             const win = window.open('/ccp/' + recordedit.key + "?rfc=" + facturaArchivos.rfc + "&d=" + dates.getTime() + "&t=" + 1, "_blank");
             win.focus();
         }else{
             //alert("here");
         }*/
    }
    const asyncparser = async (obj) => {
        if (obj) {
            let xmlstring = obj.cfdixml;
            //let fixedXml=xmlstring.replace('cfdi:', '');
            let fixedXml = xmlstring.split('cfdi:').join('');
            fixedXml = xmlstring.split('$').join('obj');

            let response = null;
            await xml2js.parseString(fixedXml, async (err, result) => {
                if (err) {
                    console.log(err);
                }
                else {
                    // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                    // let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                    console.log("primer xml", result);
                    response = result;
                    // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
                }

            });

            // console.log("response xml", response);
            return response;
        } else {
            return null;
        }

    }

    const descargarFacturaPDF = () => {
        if (facturaArchivos) {
            downloadPDF(facturaArchivos.pdf);
            /*getFacturaArchivos(facturaArchivos).then(docu => {
                downloadPDF(docu.data().pdf);
            }).catch(err => { });*/
        }
    }
    const descargarFacturaXML = () => {
        if (facturaArchivos) {
            var xmltext = facturaArchivos.cfdixml;

            let nombrefactura = "";
            if (ncarta) {
                nombrefactura = ncarta || "";
            }
            var filename = `factura${nombrefactura}.xml`;
            var pom = document.createElement('a');
            var bb = new Blob([xmltext], { type: 'text/plain' });

            pom.setAttribute('href', window.URL.createObjectURL(bb));
            pom.setAttribute('download', filename);

            pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
            pom.draggable = true;
            pom.classList.add('dragout');

            pom.click();
            /*
            getFacturaArchivos(facturaArchivos).then(docu => {
                downloadXML(docu.data().xml);
            }).catch(err => { });*/
        }
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Descargar Factura" : `Generar CFDI`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <LoadingOverlay
                        active={chekingCSD}
                        spinner
                        text='Comprobando CSD, espere ...'
                    >
                        {
                            isFacuturaCancelada && <div>
                                Factura cancelada, generar nuevo CFDI.
                            </div>
                        }
                        {
                            (facturaArchivos) ? <div>
                                <div className="row">
                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => verComplementoCartaPorte()} className="btn btn-primary col-12"> Descargar PDF</div>
                                    </div>
                                    <div style={{ paddingRight: 20, paddingLeft: 20 }} className="col-6">
                                        <div onClick={() => descargarFacturaXML()} className="btn btn-primary col-12"> Descargar XML</div>
                                    </div>
                                </div>
                            </div> :
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">CFDI</p></div>

                                            <div class="dataBox">

                                                <div class="dataRow">
                                                    <p className="fixedP">Versión CFDI</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setVersion(e.target.value)} value={version} required >
                                                            {/*<option key="ver33" value={`3.3`} >{`3.3`}</option>*/}
                                                            <option key="ver40" value={`4.0`} >{`4.0`}</option>
                                                        </select>

                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">Cliente<label className="requiredlabel">*</label></p>
                                                    <div class="fieldInput">

                                                        {

                                                            !isEdit && cliente &&
                                                            <Autocomplete
                                                                id="combo-box-demo0c"
                                                                options={clientes}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                defaultValue={clientes.find(element => element.name.trim() === cliente.trim())}
                                                                className={"selectbusqueda"}
                                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                                renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                            />}

                                                        {
                                                            !isEdit && !cliente &&
                                                            <Autocomplete
                                                                id="combo-box-demo0c"
                                                                options={clientes}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                className={"selectbusqueda"}
                                                                onChange={(e, val) => setCliente(val ? val.name : "")}
                                                                renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                            />
                                                        }

                                                        {isEdit && <input type="text" value={cliente} onChange={e => setCliente(e.target.value)} variant="outlined" disabled />}


                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">Viaje</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setIsVIaje(e.target.value)} value={isViaje} required >
                                                            <option key="v01" value={`0`} >{`Otros Conceptos`}</option>
                                                            <option key="v02" value={`1`} >{`Viaje/Flete`}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                                {
                                                    isViaje === "1" &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Origen</p>
                                                        <div class="fieldInput">
                                                            <Autocomplete
                                                                id="combo-box-demo0"
                                                                options={localidades}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                defaultValue={localidades.find(element => element.name.trim() === origen.trim())}
                                                                className={classes.input}
                                                                onChange={(e, val) => setOrigen(val ? val.name : "")}
                                                                renderInput={(params) => <TextField className="reducedselect2" style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                            />

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    isViaje === "1" &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Destino</p>
                                                        <div class="fieldInput">
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={localidades}
                                                                value={localidades.find(element => element.name == destino)}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionSelected={(option, value) => option.name === value.name}
                                                                defaultValue={localidades.find(element => element.name.trim() === destino.trim())}
                                                                className={classes.input}
                                                                onChange={(e, val) => setDestino(val ? val.name : "")}
                                                                renderInput={(params) => <TextField className="reducedselect2" value={destino} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                            />

                                                        </div>
                                                    </div>
                                                }

                                                {uid && !requiereIVacustom.find(ff => ff === uid) && <div class="dataRow">
                                                    <p className="fixedP">IVA</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' value={ivatasa} onChange={e => setIvaTasa(e.target.value)} required >
                                                            <option key={"iva16"} value={`16`} >{`16%`}</option>
                                                            <option key={"iva08"} value={`8`} >{`8%`}</option>
                                                            <option key={"iva00"} value={`0`} >{`Tasa 0`}</option>
                                                            <option key={"ivanoobj"} value={`-1`} >{`No objeto`}</option>
                                                        </select>

                                                    </div>
                                                </div>}

                                                <div class="dataRow">
                                                    <p className="fixedP">Emisor</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setRfc(e.target.value)} value={rfc} required >
                                                            {
                                                                razonesSociales.map((item, index) => {
                                                                    return <option key={index} value={item.Rfc}>{item.Razon}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Referencia</p>
                                                    <div class="fieldInput">
                                                        <input type='text' value={referencia} onChange={e => setReferencia(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Segmento de Negocio</p>
                                                    <div class="fieldInput">
                                                        <select name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                                            {
                                                                usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                                    return segmentoNegocio == item.value ?
                                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                                        <option key={index} value={item.value}>{item.value}</option>

                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Comentarios</p>
                                                    <div class="fieldInput">
                                                        <textarea value={comentarios} onChange={e => setComentarios(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataTable twoCol">

                                            <div class="dataTitle"><p className="fixedP">Términos</p></div>

                                            <div class="dataBox">

                                                <div class="dataRow">
                                                    <p className="fixedP">Uso CFDI</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setUsoCfdi(e.target.value)} value={usoCfdi} required >
                                                            {
                                                                CAT_USOCFDI.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">Método de Pago</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setMetodoPago(e.target.value)} value={metodoPago} required >
                                                            {
                                                                CAT_METODOSPAGO.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">Forma de Pago</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setFormaPago(e.target.value)} value={formaPago} required >
                                                            {
                                                                CAT_FORMASPAGO.map((item, index) => {
                                                                    return <option key={index} value={`${item.Value} - ${item.Name}`} >{`${item.Value} - ${item.Name}`}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Condiciones de Pago</p>
                                                    <div class="fieldInput">
                                                        <input placeholder='Opcional' type='text' value={condicionesPago} onChange={e => setCondicionesPago(e.target.value)} />


                                                    </div>
                                                </div>

                                                <div class="dataRow">
                                                    <p className="fixedP">Moneda</p>
                                                    <div class="fieldInput">
                                                        <select name='rfc' onChange={(e) => setMoneda(e.target.value)} value={moneda} required >
                                                            <option key={"m01"} value={`MXN`} >{`MXN`}</option>
                                                            <option key={"m02"} value={`USD`} >{`USD`}</option>
                                                        </select>

                                                    </div>
                                                </div>
                                                {moneda === "USD" && <div class="dataRow">
                                                    <p className="fixedP">Tipo de Cambio</p>
                                                    <div class="fieldInput">
                                                        <input type="number" step={0.01} min={0.00} style={{ width: "90%" }} name='cliente' value={dolarprice} onChange={e => setDolarPrice(e.target.value)} />
                                                    </div>
                                                </div>}
                                                {
                                                    <div class="dataRow">
                                                        <p className="fixedP">Relación</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setExisteRelacion(e.target.value)} value={existeRelacion} required >
                                                                <option key={"r01"} value={`Si`} >{`Si`}</option>
                                                                <option key={"r02"} value={`No`} >{`No`}</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    existeRelacion === "Si" && <div class="dataRow">
                                                        <p className="fixedP">Tipo Relación</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setTipoRelacion(e.target.value)} value={tipoRelacion} required >
                                                                {
                                                                    CAT_TIPORELACION.map((item, index) => {
                                                                        return <option key={index} value={`${item.value}`} >{`${item.value} - ${item.descripcion}`}</option>
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    existeRelacion === "Si" && <div class="dataRow">
                                                        <p className="fixedP">UUID</p>
                                                        <div class="fieldInput">
                                                            <input type="text" style={{ width: "90%" }} name='cliente' value={UIDRelacion} onChange={e => setUidRelacion(e.target.value)} required />
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    isPublicoEnGeneral && <div class="dataRow">
                                                        <p className="fixedP">Periodicidad</p>
                                                        <div class="fieldInput">
                                                            <input type="text" style={{ width: "90%" }} onChange={e => console.log(e.target.value)} name='cliente' value={`Mensual`} disabled />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    isPublicoEnGeneral &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Periodo/Mes</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setMesPeriodicidad(e.target.value)} value={mesPeriodicidad} required >
                                                                <option key={"rm01"} value={`01`} >{`01 - Enero`}</option>
                                                                <option key={"rm02"} value={`02`} >{`02 - Febrero`}</option>
                                                                <option key={"rm03"} value={`03`} >{`03 - Marzo`}</option>
                                                                <option key={"rm04"} value={`04`} >{`04 - Abril`}</option>
                                                                <option key={"rm05"} value={`05`} >{`05 - Mayo`}</option>
                                                                <option key={"rm06"} value={`06`} >{`06 - Junio`}</option>
                                                                <option key={"rm07"} value={`07`} >{`07 - Julio`}</option>
                                                                <option key={"rm08"} value={`08`} >{`08 - Agosto`}</option>
                                                                <option key={"rm09"} value={`09`} >{`09 - Septiembre`}</option>
                                                                <option key={"rm10"} value={`10`} >{`10 - Octubre`}</option>
                                                                <option key={"rm11"} value={`11`} >{`11 - Noviembre`}</option>
                                                                <option key={"rm12"} value={`12`} >{`12 - Diciembre`}</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    isPublicoEnGeneral && <div class="dataRow">
                                                        <p className="fixedP">Periodo/Año</p>
                                                        <div class="fieldInput">
                                                            <input type="number" style={{ width: "90%" }} onChange={e => setYearPeriodicidadd(e.target.value + "")} name='cliente' value={yearPeriodicidad} />
                                                        </div>
                                                    </div>
                                                }
                                                {uid && requierAddenda.find(rfindadd => rfindadd === uid) &&
                                                    <div class="dataRow">
                                                        <p className="fixedP">Addenda</p>
                                                        <div class="fieldInput">
                                                            <select name='rfc' onChange={(e) => setAddendaseleccionada(e.target.value)} value={addendaseleccionada} required >
                                                                <option key={"r01"} value={`No`} >{`No`}</option>
                                                                <option key={"r02"} value={`Chrysler TPV`} >{`Chrysler TPV`}</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                    <div class="dataTable oneColmargin">
                                        <div class="dataTitle"><p className="fixedP">Conceptos</p>
                                            <button class="boton plus-button" onClick={() => {
                                                let aux = { clave: '', descripcion: '', retencion: '0', unidad: 'E48', retencionisrrate: 0, monto: 0.0, montos: '$0.0', cantidad: 1 };
                                                if (uid && requiereIVacustom.find(fu => fu === uid)) {
                                                    aux.customivar = "16";
                                                }
                                                let temp = [...partidas];
                                                temp.push(aux);
                                                setPartidas(temp);
                                            }} ></button> </div>

                                        <div class="dataBox">
                                            {
                                                partidas.map((p, index) => {
                                                    return <div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '32.5%' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Concepto</p>
                                                                    <div class="fieldInput">
                                                                        <select name='rfc' onChange={(e) => {
                                                                            let aux = conceptosData.find(f => f.id === e.target.value);
                                                                            let temp = [...partidas];
                                                                            if (aux) {
                                                                                temp[index].clave = aux.clave;
                                                                                temp[index].descripcion = aux.descripcion;
                                                                                temp[index].perzonalizada = aux.descripcion;
                                                                                setPartidas(temp);
                                                                            }

                                                                        }} value={p.id} required >
                                                                            <option key={"vaciocon"} value={``} >{``}</option>
                                                                            {
                                                                                conceptosData.map((item, index) => {
                                                                                    return <option key={index} value={`${item.id}`} >{`${item.clave} - ${item.descripcion}`}</option>
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '14%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Cantidad</p>
                                                                    <div class="fieldInput">
                                                                        <input style={{ width: "70px" }} type='number' min={1} step={0.01} value={p.cantidad} onChange={e => {
                                                                            let temp = [...partidas];
                                                                            temp[index].cantidad = e.target.value;
                                                                            setPartidas(temp);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '18%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p style={{ width: 'auto' }} className="fixedP">Monto</p>
                                                                    <div class="fieldInput">
                                                                        <CurrencyInput value={p.montos} onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                                            let temp = [...partidas];
                                                                            temp[index].monto = floatvalue;
                                                                            temp[index].montos = maskedvalue;
                                                                            setPartidas(temp);
                                                                        }} prefix="$" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {uid && requiereIVacustom.find(ff => ff === uid) && <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">IVA</p>
                                                                    <div class="fieldInput">
                                                                        <select name='ivac' value={p.customivar} onChange={e => {
                                                                            let temp = [...partidas];
                                                                            temp[index].customivar = e.target.value;
                                                                            setPartidas(temp);
                                                                        }} required >
                                                                            <option key={"retvalue4"} value={`16`} >{`16%`}</option>
                                                                            <option key={"retvalue125"} value={`8`} >{`8%`}</option>
                                                                            <option key="retvalue533" value={`0`} >{`Tasa 0%`}</option>
                                                                            <option key={"retvalue0"} value={`-1`} >{`No Objeto`}</option>

                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Retención</p>
                                                                    <div class="fieldInput">
                                                                        <select name='rfc' onChange={(e) => {
                                                                            let temp = [...partidas];
                                                                            temp[index].retencion = e.target.value;
                                                                            setPartidas(temp);

                                                                        }} value={p.retencion} required >
                                                                            <option key="r01" value={`0`} >{`0`}</option>
                                                                            <option key="r02" value={`4`} >{`4%`}</option>
                                                                            <option key="r03" value={`5.33`} >{`5.33%`}</option>                                                                        
                                                                            {(uid === "BoGosidpskNN0ggjtrAwHPcug1A2" || uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1" || uid === "iett263mGubaKMIhpEaosbx4Rdu2") && <option key="r03" value={`10.6667`} >{`10.6667%`}</option>}
                                                                            {uid === "OvQ0BgHR2fS8ACZgKk6Vj4Q8UMC3" && <option key="r03" value={`10.6667`} >{`2/3 partes`}</option>}
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {uid && !requiereIVacustom.find(ff => ff === uid) && <div style={{ width: '16.5%', marginLeft: '2px' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Ret.ISR</p>
                                                                    <div class="fieldInput">
                                                                        <select name='rfc' onChange={(e) => {
                                                                            let temp = [...partidas];
                                                                            temp[index].retencionisrrate = e.target.value;
                                                                            setPartidas(temp);

                                                                        }} value={p.retencionisrrate} required >
                                                                            <option key="r01" value={0} >{`0`}</option>
                                                                            <option key="r01" value={0.0125} >{`1.25%`}</option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            <div style={{ width: '5.5%', marginTop: '8px', marginLeft: '2px' }}>
                                                                <button class="minus-button" onClick={() => {
                                                                    let aux = [];
                                                                    partidas.forEach((element, i) => {
                                                                        if (i === index) {
                                                                            // aux.push(value);
                                                                        } else {
                                                                            aux.push(element);
                                                                        }
                                                                    });
                                                                    setPartidas(aux);
                                                                }}><span></span></button>
                                                            </div>
                                                        </div>
                                                        {uid && <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '52.5%' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Descripción</p>
                                                                    <div class="fieldInput">
                                                                        <input type='text' value={p.perzonalizada} onChange={e => {
                                                                            let temp = [...partidas];
                                                                            temp[index].perzonalizada = e.target.value;
                                                                            setPartidas(temp);
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '23.5%' }}>
                                                                <div class="dataRow">
                                                                    <p className="fixedP">Unidad</p>
                                                                    <div class="fieldInput">
                                                                        <select name='unidadc' value={p.unidad} onChange={e => {
                                                                            let temp = [...partidas];
                                                                            temp[index].unidad = e.target.value;
                                                                            setPartidas(temp);
                                                                        }} required >
                                                                            <option key={"retvalue4"} value={`E48`} >{`E48 - Servicio`}</option>
                                                                            <option key={"retvalue125"} value={`H87`} >{`H87 - Pieza`}</option>
                                                                            <option key={"retvalue126"} value={`ACT`} >{`ACT - Actividad`}</option>
                                                                            <option key={"retvalue128"} value={`C62`} >{`C62 - Uno`}</option>

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        uid && requierAddenda.find(fadd => fadd === uid) && addendaseleccionada !== "No" && <div class="dataTable oneColmargin">
                                            <div class="dataTitle"><p className="fixedP">Addenda</p>

                                            </div>
                                            <div class="dataBox">
                                                <div class="dataRow">
                                                    <p className="fixedP">Referencia</p>
                                                    <div class="fieldInput">
                                                        <input type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.ReferenciaProveedor = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.ReferenciaProveedor} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p style={{ width: '100px' }} className="fixedP">Cve. Proveedor</p>
                                                    <div class="fieldInput" style={{ width: '200px' }}>
                                                        <input style={{ width: '200px' }} type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.CveProveedor = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.CveProveedor} />

                                                    </div>
                                                    <p className="fixedP">Proveedor</p>
                                                    <div class="fieldInput">
                                                        <input type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.Proveedor = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.Proveedor} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p style={{ width: '100px' }} className="fixedP">Cve. Origen</p>
                                                    <div class="fieldInput" style={{ width: '200px' }}>
                                                        <input style={{ width: '200px' }} type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.CveOrigen = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.CveOrigen} />

                                                    </div>
                                                    <p className="fixedP">Origen</p>
                                                    <div class="fieldInput">
                                                        <input type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.Origen = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.Origen} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p style={{ width: '100px' }} className="fixedP">Cve. Destino</p>
                                                    <div class="fieldInput" style={{ width: '200px' }}>
                                                        <input style={{ width: '200px' }} type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.CveDestino = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.CveDestino} />

                                                    </div>
                                                    <p className="fixedP">Destino</p>
                                                    <div class="fieldInput">
                                                        <input type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.Destino = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.Destino} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p style={{ width: '100px' }} className="fixedP">Cve. Reciving</p>
                                                    <div class="fieldInput" style={{ width: '200px' }}>
                                                        <input style={{ width: '200px' }} type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.CveReciving = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.CveReciving} />

                                                    </div>
                                                    <p className="fixedP">Reciving</p>
                                                    <div class="fieldInput">
                                                        <input type="text" onChange={e => {
                                                            let d = { ...dataAddendaC };
                                                            d.Reciving = e.target.value;
                                                            setDataAddendaC(d);
                                                        }} name='cliente' value={dataAddendaC.Reciving} />

                                                    </div>
                                                </div>
                                                <div class="dataRow">
                                                    <p className="fixedP">Notas</p>
                                                    <div role='button' class="boton plus-button" onClick={() => {
                                                        let notas = [...dataAddendaC.Notas, ''];
                                                        let ob = { ...dataAddendaC };
                                                        ob.Notas = notas;
                                                        setDataAddendaC(ob);
                                                    }} ></div>


                                                </div>
                                                {
                                                    dataAddendaC.Notas.map((nn, ind) => {
                                                        return <div class="dataRow">
                                                            <p className="fixedP">Nota</p>
                                                            <input type="text" style={{ width: '500px' }} onChange={e => {
                                                                let d = { ...dataAddendaC };
                                                                let notass = [...dataAddendaC.Notas];
                                                                notass[ind] = e.target.value;
                                                                d.Notas = [...notass];
                                                                setDataAddendaC(d);
                                                            }} name='cliente' value={nn} />
                                                        </div>
                                                    })
                                                }
                                                <div class="dataRow">
                                                    <p className="fixedP">Cancelaciones</p>
                                                    <div role='button' class="boton plus-button" onClick={() => {
                                                        let cancelaciones = [...dataAddendaC.Cancelaciones, ''];
                                                        let ob = { ...dataAddendaC };
                                                        ob.Cancelaciones = cancelaciones;
                                                        setDataAddendaC(ob);
                                                    }} ></div>


                                                </div>
                                                {
                                                    dataAddendaC.Cancelaciones.map((nn, ind) => {
                                                        return <div class="dataRow">
                                                            <p className="fixedP">CancelaSustituye</p>
                                                            <input type="text" style={{ width: '500px' }} onChange={e => {
                                                                let d = { ...dataAddendaC };
                                                                let canss = [...dataAddendaC.Cancelaciones];
                                                                canss[ind] = e.target.value;
                                                                d.Cancelaciones = [...canss];
                                                                setDataAddendaC(d);
                                                            }} name='cliente' value={nn} />
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    }
                                </div>
                        }
                    </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                    {!facturaArchivos && <div style={{ marginRight: 'auto' }}> <button type="button" className="boton secBtn" onClick={e => handlePreview()} >Vista Preliminar</button></div>}
                    {!facturaArchivos && <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>}
                    <div style={{ marginRight: 'auto' }}>Timbres:{`${ntibmres}`}</div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    {!facturaArchivos && <button type="submit" className="boton secBtn"> Aceptar</button>}
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;